import axios from 'utils/axios';
import { RIDER_LIST, ADD_RIDER, FAKE_USER_LIST } from 'utils/axios/endpoints';

export const handleGetRiderList = async (params = '') => {
  const res = await axios.get(RIDER_LIST + params);
  return res;
};

export const handleGetfakeUsers = async (
  params = '',
  pagination = '?page=1&limit=10'
) => {
  const res = await axios.get(FAKE_USER_LIST + pagination + params);
  return res;
};

export const handleAddRider = async payload => {
  const res = await axios.post(ADD_RIDER, payload);
  return res;
};
