import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { useAppSelector } from 'store/hook';
import { Col } from 'react-bootstrap';

const Message = ({ message, senderUserId, time, img, m_type, attachement }) => {
  const { userInfo } = useAppSelector(state => state.app);

  const isLeft = senderUserId !== userInfo.id;
  return (
    <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
      {isLeft && (
        <Avatar
          size="l"
          className="me-2"
          src={`${process.env.REACT_APP_IMAGE_BASE_URL}${img}`}
        />
      )}
      <div
        className={classNames('flex-1', {
          'd-flex justify-content-end': !isLeft
        })}
      >
        <div
          className={classNames('w-xxl-75', {
            'w-100': !isLeft
          })}
        >
          <Flex
            alignItems="center"
            className={classNames('hover-actions-trigger', {
              'flex-end-center': !isLeft,
              'align-items-center': isLeft
            })}
          >
            {m_type === 'image' ? (
              <div className="chat-message chat-gallery">
                <Col xs={6} md={4} className="px-1" style={{ minWidth: 50 }}>
                  <img
                    className="mb-2 cursor-pointer"
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}Uploads/${attachement}`}
                    alt="img"
                    width={500}
                    height={500}
                  />
                </Col>
              </div>
            ) : (
              <div
                className={classNames('p-2 rounded-2 chat-message', {
                  'bg-200': isLeft,
                  'bg-primary text-white light': !isLeft
                })}
              >
                {(message || message.text) && (
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: message.text ? message.text : message
                    }}
                  />
                )}
              </div>
            )}
          </Flex>
          <div
            className={classNames('text-400 fs--2', {
              'text-end': !isLeft
            })}
          >
            {time}
          </div>
        </div>
      </div>
    </Flex>
  );
};

Message.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  senderUserId: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  attachement: PropTypes.string.isRequired,
  m_type: PropTypes.string.isRequired
};

export default Message;
