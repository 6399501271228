import axios from 'utils/axios';
import {
  ADD_SYSTEM_PROPERTY_CATEGORY,
  DELETE_SYSTEM_PROPERTY_CATEGORY,
  SYSTEM_PROPERTY_CATEGORY_LIST,
  UPDATE_SYSTEM_PROPERTY_CATEGORY
} from 'utils/axios/endpoints';

export const handleGetPropertyList = async (params = '') => {
  const res = await axios.get(SYSTEM_PROPERTY_CATEGORY_LIST + params);
  return res;
};

export const handleAddPropertyCategory = async payload => {
  const res = await axios.post(ADD_SYSTEM_PROPERTY_CATEGORY, payload);
  return res;
};

export const handleUpdatePropertyCategory = async (payload, id) => {
  const res = await axios.patch(UPDATE_SYSTEM_PROPERTY_CATEGORY + id, payload);
  return res;
};

export const handleDeleteRecordApi = async id => {
  const res = await axios.get(DELETE_SYSTEM_PROPERTY_CATEGORY + id);
  return res;
};
