import axios from 'utils/axios';
import {
  DRIVER_DETAILS,
  DRIVER_LIST,
  UPDATE_DRIVER_DETAILS
} from 'utils/axios/endpoints';

export const handleGetDriverList = async (params = '') => {
  const res = await axios.get(DRIVER_LIST + params);
  return res;
};

export const GetUserInfo = async ({ id }) => {
  const res = await axios.get(DRIVER_DETAILS + id);
  return res;
};

export const HandleUpdateVehicleStatus = async data => {
  const res = await axios.post(UPDATE_DRIVER_DETAILS, data);
  return res;
};
