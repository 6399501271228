import { handleAddRegion } from 'api-service/region';
import { timezones } from 'data/events/timezones';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useAddRider = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [coordinates, setCoordinates] = useState('');

  const formArr = [
    {
      title: 'Name',
      name: 'name',
      type: 'text',
      required: true
    },
    {
      title: 'Distance Unit',
      name: 'distance_unit',
      type: 'select',
      listArr: [
        { value: 'km', label: 'KM' },
        { value: 'mile', label: 'Mile' }
      ],
      required: true
    },
    {
      title: 'Timezone',
      name: 'timezone',
      type: 'select',
      listArr: timezones,
      required: true
    },
    {
      title: 'Status',
      name: 'status',
      type: 'select',
      listArr: [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' }
      ],
      required: true
    }
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = async data => {
    setIsLoading(true);
    console.log(data);
    data.coordinates = coordinates;
    await handleAddRegion(data);
    setIsLoading(false);
    navigate('/region-list');
  };

  return {
    formArr,
    control,
    errors,
    isLoading,
    setCoordinates,
    onSubmit,
    register,
    handleSubmit
  };
};
