import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

const Activity = ({ histories }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <h4>Activity Timeline</h4>
      </Card.Header>
      <Card.Body className="text-justify text-1000">
        <div className="holo-timeline m-0 d-flex align-items-center justify-content-between position-relative">
          <ul className="list-inline p-0 m-0">
            {histories &&
              histories.reverse().map((el, index) => (
                <li key={index} className="position-relative">
                  <div className="timeline-dots1 border-primary text-primary"></div>
                  <h6 className="text-left mb-1">{`${el?.history_message} ${
                    el.history_type == 'cancelled'
                      ? `by: ${
                          el.history_data?.rider_name
                            ? el.history_data?.rider_name
                            : JSON.parse(el.history_data).rider_name
                        }`
                      : ''
                  }`}</h6>
                  <small className="text-right mt-1">
                    {moment(el?.updated_at).format('hh:mm MMMM D YYYY')}
                  </small>
                  <div className="d-inline-block w-100"></div>
                </li>
              ))}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Activity;

Activity.propTypes = {
  histories: PropTypes.array
};
