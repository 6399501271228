import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import Avatar from 'components/common/Avatar';
import { store, setToken } from 'store';
import { useAppSelector } from 'store/hook';

const ProfileDropdown = () => {
  const { userInfo } = useAppSelector(state => state.app);
  const handleLogout = () => {
    localStorage.clear();
    store.dispatch(setToken(''));
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={'button'}
        className="pe-0 btn  ps-2 nav-link"
      >
        <Avatar
          src={`${process.env.REACT_APP_IMAGE_BASE_URL}${userInfo?.profile_image}`}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item onClick={handleLogout} as={Link}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
