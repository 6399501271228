import axios from 'utils/axios';
import {
  ADD_REGION,
  DELETE_REGION,
  REGION_LIST,
  UPDATE_REGION
} from 'utils/axios/endpoints';

export const handleGetRegionList = async (params = '') => {
  const res = await axios.get(REGION_LIST + params);
  return res;
};

export const handleAddRegion = async data => {
  const res = await axios.post(ADD_REGION, data);
  return res;
};

export const handleUpdateRegion = async (data, id) => {
  const res = await axios.patch(UPDATE_REGION + id, data);
  return res;
};

export const handleDeleteRegionRecord = async id => {
  const res = await axios.get(DELETE_REGION + id);
  return res;
};
