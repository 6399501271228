import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { useLogin } from './helper';

const LoginForm = ({ hasLabel, layout }) => {
  const { onSubmit, register, handleSubmit, errors, isLoading } = useLogin();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Phone Number</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Phone Number' : ''}
          name="phone_number"
          type="text"
          isInvalid={!!errors?.phone_number}
          {...register('phone_number', {
            required: `phone number is required field.`
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.phone_number && errors?.phone_number?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          name="password"
          type="password"
          isInvalid={!!errors?.password}
          {...register('password', {
            required: 'password is required field.'
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.password && errors?.password?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input type="checkbox" name="remember" />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          style={{
            background: '#FFBC11',
            border: '1px solid #FFBC11',
            height: '40px',
            color: '#000'
          }}
          type="submit"
          color="primary"
          className="mt-3 w-100 login-btn"
          disabled={isLoading}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
