import React , { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRiderList } from './helper';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Loading from 'components/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useExportTable from 'hooks/useExportTable';
import ActionButton from 'components/common/ActionButton';
import {Dropdown, Modal, Image } from 'react-bootstrap';
const RiderList = () => {
  const { isLoading, riderList, handleVehicleAction } = useRiderList();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleChangeStatus = (id, status) => {
    handleVehicleAction({ id, type: 'User', status });
  };
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };
  const data = `const columns = [
  {
    accessor: 'id',
    Header: 'No'
  },
  {
    accessor: 'first_name',
    Header: 'First Name'
  },
  {
    accessor: 'last_name',
    Header: 'Last Name'
  },
  {
    accessor: 'email',
    Header: 'Email'
  },
  {
    accessor: 'phone_number',
    id: 'phone_number',
    Header: 'Phone No',
    Cell: ({ row }) =>\`\${row.original.dial_code} \${row.original.phone_number}\`
  },
  {
    accessor: 'cnic',
    id: 'cnic',
    Header: 'CNIC',
    Cell: ({ row }) => (<span>{row.original.cnic}</span>)
  },
  {
    accessor: 'cnic_front',
    id: 'cnic_front',
    Header: 'CNIC Front',
    Cell: ({ row }) => (
      <td role="cell">
        {row.original.cnic_front && (
          <Image
          src={'https://holo-app.com/holo/' + row.original.cnic_front}
          fluid
          style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }}
          onClick={() => handleImageClick('https://holo-app.com/holo/'+row.original.cnic_front)}
        />
        )}
      </td>
    )
  },
  {
    accessor: 'cnic_back',
    id: 'cnic_back',
    Header: 'CNIC Back',
    Cell: ({ row }) => (
      <td role="cell">
        {row.original.cnic_back && (
          <Image
  src={'https://holo-app.com/holo/' + row.original.cnic_front}
  fluid
  style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }}
  onClick={() => handleImageClick('https://holo-app.com/holo/'+row.original.cnic_front)}
/>
        )}
      </td>
    )
  },
  
  {
    accessor: 'created_at',
    Header: 'Created At'
  },
  {
    id: 'status',
    Header: 'Status',
    Cell: ({row}) => (
      <span>{row.original.status}</span>
    )
  },
  {
    Header: 'Action',
    Cell: ({ row }) => (
      <div class='d-flex'>
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-button"
            className="text-400 dropdown-caret-none me-2"
            variant="action"
            size="sm"
          >
            <FontAwesomeIcon icon="pencil" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-2 border">
            <Dropdown.Item onClick={() => handleChangeStatus(row.original.id, 'pending')} disabled={['pending'].includes(row.original.status)} className="cursor-pointer">Pending</Dropdown.Item>
            <Dropdown.Item onClick={() => handleChangeStatus(row.original.id, 'active')} disabled={['active'].includes(row.original.status)} className="cursor-pointer">Active</Dropdown.Item>
            <Dropdown.Item onClick={() => handleChangeStatus(row.original.id, 'in_active')} disabled={['in_active'].includes(row.original.status)} className="cursor-pointer">In Active</Dropdown.Item>
            <Dropdown.Item onClick={() => handleChangeStatus(row.original.id, 'block')} disabled={['block'].includes(row.original.status)} className="cursor-pointer">Block</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Link to={\`/user/\${row.original.id}/ride-bookings\`} className="p-0 me-2">
          <ActionButton icon="car-side" title="View Ride Bookings" variant="action" className="p-0 me-2" />
        </Link>
        <Link to={\`/user/\${row.original.id}/hotel-bookings\`} className="p-0 me-2">
          <ActionButton icon="landmark" title="View Hotel Bookings" variant="action" className="p-0 me-2" />
        </Link>
      </div>
    )
  },

];

  const data = ${JSON.stringify(riderList, null, 2)}`;

  const searchableTableCode = `${data}

  function AdvanceTableExample() {

    return (
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            className: 'fs--1 mb-0 overflow-hidden',
            userTable: true
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    );
  }

  render(isLoading ?  <Loading className={"loadingTable"} /> : <AdvanceTableExample />)`;

  const exportColumns = [
    'id',
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'status'
  ];
  const exportToExcel = useExportTable(
    riderList,
    'riderList.xlsx',
    exportColumns
  );

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header
        title="Consumer Details"
        light={false}
        className="border-bottom border-200"
        exportButton={riderList?.length > 0 ? exportToExcel : null}
        isLoading={isLoading}
      />
      <FalconComponentCard.Body
        code={searchableTableCode}
        scope={{
          AdvanceTableWrapper,
          AdvanceTable,
          AdvanceTableFooter,
          AdvanceTableSearchBox,
          isLoading,
          Loading,
          Dropdown,
          FontAwesomeIcon,
          handleChangeStatus,
          handleCloseModal,
          handleImageClick,
          Link,
          ActionButton
        }}
        language="jsx"
        noInline
        noLight
      />
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body className="text-center">
          {selectedImage && <Image src={selectedImage} fluid />}
        </Modal.Body>
      </Modal>
    </FalconComponentCard>
  );
};

export default RiderList;
