import React from 'react';
import { Card, Col, Image, Row, ListGroup } from 'react-bootstrap';
import Activity from 'components/common/Activity';

import { useRideRequestProfile } from './helper';
import ProfileIntro from './ProfileIntro';

const Profile = () => {
  const { rideRequestInfo } = useRideRequestProfile();

  return (
    <>
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <Card className="mb-3">
            <Card.Header className="bg-light d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Ride Request</h5>
              <h5 className="mb-0">#{rideRequestInfo.id}</h5>
            </Card.Header>
            <Card.Body className="text-justify text-1000">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Pickup Address:</strong>{' '}
                  {rideRequestInfo.start_address}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Drop Address:</strong> {rideRequestInfo.end_address}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Total Distance:</strong>{' '}
                  {rideRequestInfo.total_distance}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Admin Commission:</strong>{' '}
                  {rideRequestInfo.admin_commission}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Sub Total:</strong> {rideRequestInfo.sub_total}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Status:</strong>{' '}
                  {rideRequestInfo.status?.split('_').join(' ')}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Header className="bg-light d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Service Detail</h5>
            </Card.Header>
            <Card.Body className="text-justify text-1000">
              <Row>
                <Col xs={12} md={12} className="d-flex justify-content-center">
                  <Image
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}${rideRequestInfo?.service_details?.image}`}
                    width={'130px'}
                    height={'130px'}
                    className="m-auto"
                    rounded
                    alt={'profile img'}
                  />
                </Col>
                <h1
                  style={{
                    margin: '10px auto',
                    fontSize: '18px',
                    textAlign: 'center'
                  }}
                >
                  {rideRequestInfo?.service_details?.name}
                </h1>
                <h1
                  style={{
                    margin: '10px auto',
                    fontSize: '18px',
                    textAlign: 'center'
                  }}
                >
                  {rideRequestInfo?.service_details?.description}
                </h1>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <Activity histories={rideRequestInfo.rideRequestHistory} />
        </Col>
        <Col lg={4}>
          {!(
            [null, undefined].includes(
              rideRequestInfo.driver_details?.first_name
            ) || rideRequestInfo.driver_details?.first_name == ''
          ) && (
            <ProfileIntro
              header="Driver Details"
              userInfo={rideRequestInfo.driver_details}
            />
          )}
          {!(
            [null, undefined].includes(
              rideRequestInfo.rider_detail?.first_name
            ) || rideRequestInfo.rider_detail?.first_name == ''
          ) && (
            <ProfileIntro
              header="Rider Details"
              userInfo={rideRequestInfo.rider_detail}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Profile;
