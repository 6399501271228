import { handleGetChatMessageList, handleSendMessage } from 'api-service/chat';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { store, setMessages } from 'store';
import { setScrollToBottom } from 'store';
import { useAppSelector } from 'store/hook';

const MessageTextArea = () => {
  const { receiverId } = useAppSelector(state => state.app);
  const [message, setMessage] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    let newMessage = {
      to_user_id: receiverId,
      message_number: Math.floor(Math.random() * 10000) * 4,
      message,
      m_type: 'Text'
    };

    const res = await handleSendMessage(newMessage);

    if (res) {
      setMessage('');
      const res = await handleGetChatMessageList(receiverId);
      store.dispatch(setMessages(res?.data?.result?.messages));
      store.dispatch(setScrollToBottom(true));
    }
  };

  return (
    <Form className="chat-editor-area" onSubmit={handleSubmit}>
      <TextareaAutosize
        minRows={1}
        maxRows={6}
        value={message}
        placeholder="Type your message"
        onChange={({ target }) => setMessage(target.value)}
        className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
      />

      <Button
        variant="send"
        size="sm"
        className={classNames('shadow-none', {
          'text-primary': message.length > 0
        })}
        type="submit"
      >
        Send
      </Button>
    </Form>
  );
};

MessageTextArea.propTypes = {
  thread: PropTypes.object
};

export default MessageTextArea;
