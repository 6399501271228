import {
  handleDeleteServiceRecord,
  handleGetServiceList
} from 'api-service/services';
import { useEffect, useState } from 'react';
import useRangeParams from 'hooks/useRangeParams';

export const useServiceList = () => {
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [deleteId, setDeleteId] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { params } = useRangeParams();

  const getServiceList = async () => {
    const res = await handleGetServiceList(params);
    setIsLoading(true);
    if (res?.status === 200) {
      setIsLoading(false);
      setServiceList(res?.data?.result);
    }
  };

  const handleDeleteRecord = async () => {
    setDeleteLoading(true);
    const res = await handleDeleteServiceRecord(deleteId);
    if (res?.status === 200) {
      getServiceList();
      setDeleteLoading(false);
      setOpenDelete(false);
    }
  };

  useEffect(() => {
    getServiceList();
  }, [params]);

  return {
    deleteLoading,
    serviceList,
    openDelete,
    isLoading,
    setDeleteId,
    setOpenDelete,
    setDeleteLoading,
    handleDeleteRecord
  };
};
