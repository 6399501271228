import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ProfileIntro = ({ userInfo, header }) => {
  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0">{header}</h5>
        </Card.Header>
        <Card.Body className="text-justify text-1000">
          <Row>
            <Col xs={12} md={12} className="d-flex justify-content-center">
              <Image
                src={`${process.env.REACT_APP_IMAGE_BASE_URL}${userInfo?.profile_image}`}
                width={'130px'}
                height={'130px'}
                className="m-auto"
                rounded
                alt={'profile img'}
              />
            </Col>
            <h1
              style={{
                margin: '10px auto',
                fontSize: '18px',
                textAlign: 'center'
              }}
            >{`Name: ${userInfo?.first_name} ${userInfo?.last_name}`}</h1>
            <h1
              style={{
                margin: '10px auto',
                fontSize: '18px',
                textAlign: 'center'
              }}
            >{`Phone No: +92 ${userInfo?.phone_number}`}</h1>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileIntro;

ProfileIntro.propTypes = {
  userInfo: PropTypes.object,
  header: PropTypes.string.isRequired
};
