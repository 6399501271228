import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { privateRoutes, publicRoutes } from './helper';
import { useAppSelector } from 'store/hook';
import MainLayout from 'layouts/MainLayout';

const Routing = () => {
  const { token, isLoading } = useAppSelector(state => state?.app);
  return (
    <>
      {!token && (
        <Routes>
          {publicRoutes?.map(({ path, element }, index) => (
            <Route key={index} path={path} element={element} />
          ))}
          <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
      )}
      {isLoading && (
        <div>
          <h3 style={{ display: 'block' }}>loading.....</h3>
        </div>
      )}
      {token && (
        <Routes>
          {privateRoutes?.map(({ path, element }, index) => (
            <Route
              key={index}
              path={path}
              element={<MainLayout>{element}</MainLayout>}
            />
          ))}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      )}
    </>
  );
};

export default Routing;
