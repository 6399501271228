import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import FalconComponentCard from 'components/common/FalconComponentCard';
import { useAddRider } from './helper';
import MapComponent from './map';

const AddRegion = () => {
  const { formArr, errors, onSubmit, register, handleSubmit, setCoordinates } = useAddRider();

  // Create state variables for each select option
  const [selectedOptions, setSelectedOptions] = useState({});

  // Handler to update the selected option for a particular select
  const handleChange = (name, selectedOption) => {
    console.log(name);
    console.log(selectedOption);
    setSelectedOptions({ ...selectedOptions, [name]: selectedOption });
    // Update the form value when select value changes
    register(name, { value: selectedOption.value }); // This will update the form values
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header title="Add Region" light={false} />
      <FalconComponentCard.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            {formArr.map(({ name, title, type, listArr, required }) => {
              return (
                <Col key={name} md="4">
                  {type !== 'select' && (
                    <Form.Group className="mb-3" controlId={`formHook${name}`}>
                      <Form.Label>
                        {title} {required && '*'}
                      </Form.Label>
                      <Form.Control
                        type={type}
                        name={name}
                        placeholder={`Enter your ${title}`}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: required ? `${title} is required field.` : false
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                  {type === 'select' && (
                    <Form.Group className="mb-3" controlId={`formHook${name}`}>
                      <Form.Label>{title} *</Form.Label>
                      <Select
                        value={selectedOptions[name]} // Use specific value from state
                        onChange={(selectedOption) => handleChange(name, selectedOption)}
                        options={listArr}
                        isSearchable={true} // Enables search functionality
                        placeholder={`Select ${title}`}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              );
            })}

            <MapComponent setCoordinates={setCoordinates} />
          </Row>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default AddRegion;
