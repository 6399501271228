import React from 'react';
import PropTypes from 'prop-types';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';

import useToggleStyle from './hooks/useToggleStyle';

import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

const Main = ({ children }) => {
  const configState = {
    isFluid: getItemFromStore('isFluid', true),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };
  const { isLoaded } = useToggleStyle(configState.isRTL, configState.isDark);

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: configState.isDark
            ? getColor('dark')
            : getColor('light')
        }}
      />
    );
  }

  return children;
};

Main.propTypes = { children: PropTypes.node };

export default Main;
