import axios from 'utils/axios';
import {
  ADD_SYSTEM_PROPERTY_SERVICE,
  DELETE_SYSTEM_PROPERTY_SERVICE,
  SYSTEM_PROPERTY_SERVICE_LIST,
  UPDATE_SYSTEM_PROPERTY_SERVICE
} from 'utils/axios/endpoints';

export const handleGetSystemPropertyServiceList = async (params = '') => {
  const res = await axios.get(SYSTEM_PROPERTY_SERVICE_LIST + params);
  return res;
};

export const handleAddPropertyServices = async payload => {
  const res = await axios.post(ADD_SYSTEM_PROPERTY_SERVICE, payload);
  return res;
};

export const handleUpdatePropertyServices = async (payload, id) => {
  const res = await axios.patch(UPDATE_SYSTEM_PROPERTY_SERVICE + id, payload);
  return res;
};

export const handleDeleteServiceRecordApi = async id => {
  const res = await axios.get(DELETE_SYSTEM_PROPERTY_SERVICE + id);
  return res;
};
