import { configureStore } from '@reduxjs/toolkit';
import { appSlice } from './app-slice';

export const store = configureStore({
  reducer: { app: appSlice.reducer }
});

export const {
  setListRider,
  setToken,
  setConfig,
  setUserInfo,
  setIsLoading,
  storeReset,
  setChatThread,
  setReceiverId,
  setMessages,
  setScrollToBottom,
  setIsChatLoading
} = appSlice.actions;
