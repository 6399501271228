// auth routes
export const LOGIN_ROUTE = 'admin/login';

export const DASHBOARD_ROUTE = 'admin/dashboard';

export const REGION_LIST = 'admin/list-region';
export const ADD_REGION = 'admin/add-region';
export const DELETE_REGION = 'admin/delete-region/';
export const UPDATE_REGION = 'admin/update-region/';

export const MAP_LIST = 'admin/location';

export const SERVICE_LIST = 'admin/list-service';
export const ADD_SERVICE = 'admin/add-service';
export const DELETE_SERVICE = 'admin/delete-service/';
export const UPDATE_SERVICE = 'admin/update-service/';

export const RIDER_LIST = 'admin/get-riders';
export const FAKE_USER_LIST = 'admin/fake-people';
export const ADD_RIDER = 'admin/add-Rider';

export const DRIVER_LIST = 'admin/get-drivers';
export const PROPERTY_OWNER_LIST = 'admin/get-property-owners';
export const UPDATE_DRIVER_DETAILS = 'admin/update-driver-details';
export const UPDATE_PROPERTY_DETAILS = 'admin/update-property-owners-details';
export const DRIVER_DETAILS = 'admin/get-driver-details/';
export const PROPERTY_DETAILS = 'admin/get-property-owner-details/';

export const SYSTEM_PROPERTY_CATEGORY_LIST =
  '/admin/list-system-property-category';
export const ADD_SYSTEM_PROPERTY_CATEGORY =
  '/admin/add-system-property-category';
export const DELETE_SYSTEM_PROPERTY_CATEGORY =
  '/admin/delete-system-property-category/';
export const UPDATE_SYSTEM_PROPERTY_CATEGORY =
  '/admin/update-system-property-category/';

export const SYSTEM_PROPERTY_SERVICE_LIST =
  '/admin/list-system-property-service';
export const ADD_SYSTEM_PROPERTY_SERVICE = '/admin/add-system-property-service';
export const DELETE_SYSTEM_PROPERTY_SERVICE =
  '/admin/delete-system-property-service/';
export const UPDATE_SYSTEM_PROPERTY_SERVICE =
  '/admin/update-system-property-service/';

export const CHAT_THREADS_LIST = 'admin/message-threads';
export const CHAT_MESSAGE_LIST = 'admin/messages';
export const SEND_MESSAGE = 'admin/send-message';
export const RIDE_REQUEST_LIST = 'admin/ride-request-pagination';
export const UPDATE_RIDE_REQUEST = 'admin/ride-request/update/';
export const PROPERTY_REQUEST_LIST = 'admin/property-request-pagination';
export const UPDATE_PROPERTY_REQUEST = 'admin/property-request/update/';
export const REFUND_REQUEST = 'admin/refund-cancel-fee';
export const LIST_NOTIFICATION = 'admin/notification/list';
export const ADD_NOTIFICATION = 'admin/notification/store';
export const GET_ROLES = 'admin/role/list';
export const GET_USERS = 'admin/all-users';
