import {
  handleDeleteServiceRecordApi,
  handleGetSystemPropertyServiceList
} from 'api-service/system-property-service';
import { useEffect, useState } from 'react';
import useRangeParams from 'hooks/useRangeParams';

export const useSystemPropertyServiceList = () => {
  const [propertyService, setPropertyService] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteId, setDeleteId] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { params } = useRangeParams();

  const SystemPropertyServiceList = async () => {
    setIsLoading(true);
    const res = await handleGetSystemPropertyServiceList(params);
    if (res?.status === 200) {
      setPropertyService(res?.data?.result);
      setIsLoading(false);
    }
  };
  const handleDeleteRecord = async () => {
    setDeleteLoading(true);
    const res = await handleDeleteServiceRecordApi(deleteId);
    if (res?.status === 200) {
      SystemPropertyServiceList();
      setDeleteLoading(false);
      setOpenDelete(false);
    }
  };

  useEffect(() => {
    SystemPropertyServiceList();
  }, [params]);

  return {
    propertyService,
    isLoading,
    openDelete,
    deleteLoading,
    setDeleteId,
    setOpenDelete,
    setDeleteLoading,
    handleDeleteRecord
  };
};
