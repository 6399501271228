import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import FalconComponentCard from 'components/common/FalconComponentCard';
import { useEditPropertyService } from './helper';

const EditServiceCategory = () => {
  const {
    isLoading,
    formArr,
    errors,
    onSubmit,
    register,
    handleSubmit,
    setFormArr
  } = useEditPropertyService();

  const handleChange = (e, formArr) => {
    let newFormArr = [];
    formArr.forEach(el => {
      if (el.name == e.target.name) {
        if (el.type == 'file') {
          el.file = e.target.files;
        } else {
          el.value = e.target.value;
        }
        newFormArr.push(el);
      } else {
        newFormArr.push(el);
      }
    });
    setFormArr(newFormArr);
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header title="Edit Property Service" light={false} />
      <FalconComponentCard.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            {formArr.map(({ name, title, value, type, required }) => {
              return (
                <Col key={name} md="4">
                  {type == 'text' && (
                    <Form.Group
                      className="mb-3"
                      onChange={e => handleChange(e, formArr)}
                      controlId="formHookname"
                    >
                      <Form.Label>
                        {title} {required && '*'}
                      </Form.Label>
                      <Form.Control
                        type={type}
                        name={name}
                        value={value}
                        placeholder={`Enter your ${title}`}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: required
                            ? `${title} is required field.`
                            : false
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                  {type !== 'text' && (
                    <>
                      <Form.Group
                        className="mb-3"
                        onChange={e => handleChange(e, formArr)}
                        controlId="formHookname"
                      >
                        <Form.Label>
                          {title} {required && '*'}
                        </Form.Label>
                        <Form.Control
                          type={type}
                          name={name}
                          placeholder={`Enter your ${title}`}
                          isInvalid={!!errors?.[name]}
                          {...register(name, {
                            required: required
                              ? `${title} is required field.`
                              : false
                          })}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.[name] && errors?.[name]?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}${value}`}
                        width={45}
                        height={45}
                        alt="img"
                      />
                    </>
                  )}
                </Col>
              );
            })}
          </Row>

          <Button variant="primary" type="submit" disabled={isLoading}>
            Submit
          </Button>
        </Form>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default EditServiceCategory;
