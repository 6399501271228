import axios from 'utils/axios';
import {
  CHAT_MESSAGE_LIST,
  CHAT_THREADS_LIST,
  SEND_MESSAGE
} from 'utils/axios/endpoints';

export const handleGetChatThreadsList = async () => {
  const res = await axios.get(CHAT_THREADS_LIST);
  return res;
};

export const handleGetChatMessageList = async id => {
  const res = await axios.get(`${CHAT_MESSAGE_LIST}/${id}`);
  return res;
};

export const handleSendMessage = async data => {
  const res = await axios.post(SEND_MESSAGE, data);
  return res;
};
