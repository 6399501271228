import React from 'react';
import { Link } from 'react-router-dom';

import { usePropertyCategory } from './helper';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ActionButton from 'components/common/ActionButton';
import DeleteConfirmModal from 'components/confirm-delete-modal';
import Loading from 'components/loading';
import useExportTable from 'hooks/useExportTable';

const PropertyCategory = () => {
  const {
    isLoading,
    systemPropertyCategoryList,
    openDelete,
    deleteLoading,
    setDeleteId,
    setOpenDelete,
    setDeleteLoading,
    handleDeleteRecord
  } = usePropertyCategory();

  const data = `const columns = [
  {
    accessor: 'id',
    Header: 'No'
  },
  {
    accessor: 'image',
    Header: 'Image',
    Cell: ({ row }) => <img src={\`${
      process.env.REACT_APP_IMAGE_BASE_URL
    }\${row?.original?.image}\`} width={45} height={45} alt="img"/>
  },
  {
    accessor: 'name',
    Header: 'Name'
  },
  {
    accessor: 'sub_name',
    Header: 'Sub Name'
  },
  {
    accessor: 'region.name',
    Header: 'Region'
  },
  {
    accessor: 'minimum_rent',
    Header: 'Minimum Rent'
  },
  {
    accessor: 'admin_commission',
    Header: 'Admin Comission (%)'
  },
  {
    accessor: 'created_at',
    Header: 'Created At'
  },
  {
    Header: 'Action',
    Cell: ({ row }) => (
      <>
       <ActionButton icon="trash" onClick={() => {setDeleteId(row.original.id); setOpenDelete(true);}} title="delete" variant="action" className="p-0 me-2" />
       <Link to={\`/system-property-category/\${row.original.id}/edit\`} className="p-0 me-2">
          <ActionButton icon="pencil" onClick={() => {sessionStorage.setItem("categoryDetail" + row.original.id, JSON.stringify(row.original))}} title="Edit" variant="action" className="p-0 me-2" />
        </Link>
      </>
    )
  },
];

  const data = ${JSON.stringify(systemPropertyCategoryList, null, 2)}`;

  const searchableTableCode = `${data}

  function AdvanceTableExample() {

    return (
      <>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
      {
        openDelete && <DeleteConfirmModal deleteModalOpen={openDelete}
          deleteLoading={deleteLoading}
          setDeleteId={setDeleteId}
          handleDeleteRow={handleDeleteRecord}
          setDeleteModalOpen={setOpenDelete}/>
      }
      </>
    );
  }

  render (isLoading ? <Loading className={"loadingTable"} /> : <AdvanceTableExample />)`;

  const exportColumns = ['id', 'name', 'sub_name'];
  const exportToExcel = useExportTable(
    systemPropertyCategoryList,
    'systemPropertyCategoryList.xlsx',
    exportColumns
  );

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header
        title="hotel category"
        light={false}
        className="border-bottom border-200"
        exportButton={
          systemPropertyCategoryList?.length > 0 ? exportToExcel : null
        }
        isLoading={isLoading}
      />
      <FalconComponentCard.Body
        code={searchableTableCode}
        scope={{
          AdvanceTableSearchBox,
          AdvanceTableWrapper,
          AdvanceTableFooter,
          isLoading,
          Loading,
          AdvanceTable,
          ActionButton,
          openDelete,
          deleteLoading,
          DeleteConfirmModal,
          setOpenDelete,
          setDeleteId,
          setDeleteLoading,
          handleDeleteRecord,
          Link
        }}
        language="jsx"
        noInline
        noLight
      />
    </FalconComponentCard>
  );
};

export default PropertyCategory;
