import React, {useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row, Modal, Image} from 'react-bootstrap';

import { useDriverProfile } from './helper';
import ProfileIntro from './ProfileIntro';

const Profile = () => {
  const navigate = useNavigate();
  const { userInfo, vehicleImg, handleVehicleAction } = useDriverProfile();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };
  return (
    <>
      <Row className="g-3 mb-3">
        <Card className="mb-3">
          <Card.Header className="bg-light d-flex justify-content-between align-items-center">
            <h5 className="mb-0">View Driver</h5>
            <Button onClick={() => navigate(-1)} variant="primary">
              Back
            </Button>
          </Card.Header>
        </Card>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <ProfileIntro />
        </Col>
        <Col lg={12}>
          <Card className="mb-3">
            <Card.Header className="bg-light">
              <h5 className="mb-0">User Document Detail</h5>
            </Card.Header>
            <Card.Body alignItems="end" justifyContent="between">
              {userInfo?.userDocuments?.length > 1 ? (
                userInfo?.userDocuments?.map((ele, index) => (
                  <>
                    <div className={'d-flex justify-content-between my-4'}>
                      <div className="d-flex">
                        <h4 className="mx-2">Document Type:</h4>
                        <h4>
                          {ele.document_type
                            .split('_')
                            .map(
                              part =>
                                part.charAt(0).toUpperCase() + part.slice(1)
                            )
                            .join(' ')}
                        </h4>
                      </div>
                      <div>
                      <Button
                          variant="primary"
                          disabled={ele?.is_verified === 3}
                          onClick={() =>
                            handleVehicleAction({
                              id: ele?.id,
                              type: 'Document',
                              status: 3
                            })
                          }
                          style={{ margin: '0 10px' }}
                        >
                          Re Submit
                        </Button>
                        <Button
                          variant="primary"
                          disabled={ele?.is_verified === 2}
                          onClick={() =>
                            handleVehicleAction({
                              id: ele?.id,
                              type: 'Document',
                              status: 2
                            })
                          }
                        >
                          In-Active
                        </Button>
                        <Button
                          variant="primary"
                          disabled={ele?.is_verified === 1}
                          onClick={() =>
                            handleVehicleAction({
                              id: ele?.id,
                              type: 'Document',
                              status: 1
                            })
                          }
                          style={{ margin: '0 10px' }}
                        >
                          Active
                        </Button>
                        <Button
                          variant="primary"
                          disabled={ele?.is_verified === 0}
                          onClick={() =>
                            handleVehicleAction({
                              id: ele?.id,
                              type: 'Document',
                              status: 0
                            })
                          }
                        >
                          Pending
                        </Button>
                      </div>
                    </div>
                    <Row key={index}>
                      <Col lg={6} className="text-center">
                        <h5 className={'mb-1 text-700 fw-normal lh-1'}>
                          Front Image
                        </h5>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_BASE_URL +
                            ele.front_image
                          }
                          alt="document_img"
                          width={'50%'}
                          height={200}
                          style={{ cursor: 'pointer' }}
                              onClick={() => handleImageClick(process.env.REACT_APP_IMAGE_BASE_URL + ele.front_image)}
                         
                        />
                      </Col>
                      {ele.back_image && (
                        <Col lg={6} className="text-center">
                          <h5 className={'mb-1 text-700 fw-normal lh-1'}>
                            Back Image
                          </h5>
                          <img
                            src={process.env.REACT_APP_IMAGE_BASE_URL + ele.back_image}
                            alt="document_img"
                            width={'50%'}
                            height={200}
                            style={{ cursor: 'pointer' }}
                              onClick={() => handleImageClick(process.env.REACT_APP_IMAGE_BASE_URL + ele.back_image)}
                         
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                ))
              ) : (
                <h2 className={'mb-1 text-700 fw-normal lh-1'}>
                  No Data Found
                </h2>
              )}
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Header className="bg-light">
              <h5 className="mb-0">Vehicle Detail</h5>
            </Card.Header>
            <Card.Body alignItems="end" justifyContent="between">
              {userInfo?.vehicleDetails !== null ? (
                <>
                  <div className={'d-flex justify-content-end'}>
                    <Button
                      variant="primary"
                      disabled={userInfo?.vehicleDetails?.is_verified === 2}
                      onClick={() =>
                        handleVehicleAction({
                          id: userInfo?.vehicleDetails?.id,
                          type: 'Vehicle',
                          status: 2
                        })
                      }
                    >
                      In-Active
                    </Button>
                    <Button
                      variant="primary"
                      disabled={userInfo?.vehicleDetails?.is_verified === 1}
                      onClick={() =>
                        handleVehicleAction({
                          id: userInfo?.vehicleDetails?.id,
                          type: 'Vehicle',
                          status: 1
                        })
                      }
                      style={{ margin: '0 10px' }}
                    >
                      Active
                    </Button>
                    <Button
                      variant="primary"
                      disabled={userInfo?.vehicleDetails?.is_verified === 0}
                      onClick={() =>
                        handleVehicleAction({
                          id: userInfo?.vehicleDetails?.id,
                          type: 'Vehicle',
                          status: 0
                        })
                      }
                    >
                      Pending
                    </Button>
                  </div>
                  <Row>
                    <Col lg={4}>
                      Owner Name: {userInfo?.vehicleDetails?.owner_name}
                    </Col>
                    <Col lg={4} className={'mb-2'}>
                      Owner CNIC: {userInfo?.vehicleDetails?.cnic}
                    </Col>
                    <Col lg={4}>
                      Owner ID: {userInfo?.vehicleDetails?.owner_id_no}
                    </Col>
                    <Col lg={4}>
                      Car Brand: {userInfo?.vehicleDetails?.brand_name}
                    </Col>
                    <Col lg={4}>
                      Car Model: {userInfo?.vehicleDetails?.model}
                    </Col>
                    <Col lg={4}>
                      Car plate number: {userInfo?.vehicleDetails?.plate_no}
                    </Col>
                    <Col lg={12}>
                      <h5 className={'mb-3 mt-3 text-700 fw-normal lh-1'}>
                        Vehicle Image
                      </h5>
                      <Row>
                        {vehicleImg?.length > 0 &&
                          vehicleImg?.map((ele, index) => (
                            <Col lg={4} key={index} className={'mb-3'}>
                              <img
                                src={process.env.REACT_APP_IMAGE_BASE_URL + ele}
                                alt={'vehical image'}
                                width={'100%'}
                                height={200}
                                style={{ cursor: 'pointer' }}
                              onClick={() => handleImageClick(process.env.REACT_APP_IMAGE_BASE_URL + ele)}
                         
                              />
                            </Col>
                          ))}
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <h5 className={'mb-3 mt-3 text-700 fw-normal lh-1'}>
                        CNIC Images
                      </h5>
                      <Row>
                        {userInfo?.cnic_front && (
                          <Col lg={4} className={'mb-3'}>
                            <img
                              src={process.env.REACT_APP_IMAGE_BASE_URL + userInfo.cnic_front}
                              alt={'CNIC Front Image'}
                              width={'100%'}
                              height={200}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleImageClick(process.env.REACT_APP_IMAGE_BASE_URL + userInfo.cnic_front)}
                         
                            />
                          </Col>
                        )}
                        {userInfo?.cnic_back && (
                          <Col lg={4} className={'mb-3'}>
                            <img
                              src={process.env.REACT_APP_IMAGE_BASE_URL + userInfo.cnic_back}
                              alt={'CNIC Back Image'}
                              width={'100%'}
                              height={200}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleImageClick(process.env.REACT_APP_IMAGE_BASE_URL + userInfo.cnic_back)}
                         
                            />
                          </Col>
                        )}
                      </Row>

                    </Col>
                  </Row>
                </>
              ) : (
                <h2 className={'mb-1 text-700 fw-normal lh-1'}>
                  No Data Found
                </h2>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body className="text-center">
          {selectedImage && <Image src={selectedImage} fluid />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Profile;
