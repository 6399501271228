import React from 'react';
import PropTypes from 'prop-types';

const ChatProvider = ({ children }) => {
  return <div>{children}</div>;
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired };

export default ChatProvider;
