import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import FalconComponentCard from 'components/common/FalconComponentCard';
import { useEditRider } from './helper';
import MapComponent from './map';

const EditRegion = () => {
  const {
    formArr,
    errors,
    onSubmit,
    register,
    handleSubmit,
    setCoordinates,
    coordinates,
    setFormArr
  } = useEditRider();

  const handleChange = (e, formArr) => {
    let newFormArr = [];
    formArr.forEach(el => {
      if (el.name == e.target.name) {
        el.value = e.target.value;
        newFormArr.push(el);
      } else {
        newFormArr.push(el);
      }
    });
    setFormArr(newFormArr);
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header title="Edit Region" light={false} />
      <FalconComponentCard.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            {formArr.map(({ name, title, type, value, listArr, required }) => {
              return (
                <Col key={name} md="4">
                  {type !== 'select' && (
                    <Form.Group
                      onChange={e => handleChange(e, formArr)}
                      className="mb-3"
                      controlId="formHookname"
                    >
                      <Form.Label>
                        {title} {required && '*'}
                      </Form.Label>
                      <Form.Control
                        type={type}
                        name={name}
                        value={value}
                        placeholder={`Enter your ${title}`}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: required
                            ? `${title} is required field.`
                            : false
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                  {type === 'select' && (
                    <Form.Group
                      onChange={e => handleChange(e, formArr)}
                      className="mb-3"
                      controlId="formHooknamsdsdsde"
                    >
                      <Form.Label>{title} *</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name={name}
                        value={value}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: required
                            ? `${title} is required field.`
                            : false
                        })}
                      >
                        <option value="">Select an option</option>
                        {listArr?.map(({ value, label }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              );
            })}

            <MapComponent
              initialCoordinates={coordinates}
              setCoordinates={setCoordinates}
            />
          </Row>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default EditRegion;
