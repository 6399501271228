import axios from 'utils/axios';
import {
  ADD_SERVICE,
  DELETE_SERVICE,
  REGION_LIST,
  SERVICE_LIST,
  UPDATE_SERVICE
} from 'utils/axios/endpoints';

export const handleGetServiceList = async (params = '') => {
  const res = await axios.get(SERVICE_LIST + params);
  return res;
};

export const handleGetRegionList = async () => {
  const res = await axios.get(REGION_LIST);
  return res;
};

export const handleAddService = async payload => {
  const res = await axios.post(ADD_SERVICE, payload);
  return res;
};

export const handleUpdateService = async (payload, id) => {
  const res = await axios.patch(UPDATE_SERVICE + id, payload);
  return res;
};

export const handleDeleteServiceRecord = async id => {
  const res = await axios.get(DELETE_SERVICE + id);
  return res;
};
