import {
  handleGetDashboardData,
  handleGetRegionList
} from 'api-service/dashboard';
import { useEffect, useState } from 'react';

export const useDashboard = () => {
  const [dashboardList, setDashboardList] = useState({});
  const [regionList, setRegionList] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const regionName = searchParams.get('region') || 'Pakistan';

  const getDashboardList = async () => {
    const res = await handleGetDashboardData(regionName);
    const regionRes = await handleGetRegionList();
    if (regionRes?.status === 200) {
      setRegionList(regionRes?.data.result);
    }
    if (res?.status === 200) {
      setDashboardList(res?.data?.result?.dashboard);
    }
  };

  useEffect(() => {
    getDashboardList();
  }, []);

  return {
    dashboardList,
    regionList,
    regionName
  };
};
