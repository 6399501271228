import axios from 'utils/axios';
import {
  LIST_NOTIFICATION,
  ADD_NOTIFICATION,
  GET_ROLES,
  GET_USERS
} from 'utils/axios/endpoints';

export const handleGetNotifications = async params => {
  const res = await axios.get(LIST_NOTIFICATION + params);
  return res;
};

export const handleGetRoles = async () => {
  const res = await axios.get(GET_ROLES);
  return res;
};

export const handleGetUsers = async () => {
  const res = await axios.get(GET_USERS);
  return res;
};

export const handleAddNotification = async payload => {
  const res = await axios.post(ADD_NOTIFICATION, payload);
  return res;
};
