import { handleAddPropertyCategory } from 'api-service/system-property-category';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleGetRegionList } from 'api-service/services';
export const useAddCategory = () => {
  const [regionList, setRegionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formArr = [
    {
      title: 'Name',
      name: 'name',
      type: 'text',
      required: true
    },
    {
      title: 'Region',
      name: 'region_id',
      type: 'select',
      required: true,
      list: regionList
    },
    {
      title: 'Sub Name',
      name: 'sub_name',
      type: 'text',
      required: true
    },
    {
      title: 'Commission Type',
      name: 'commission_type',
      type: 'select',
      list: [
        {
          value: 'percent',
          label: 'Percent'
        }
      ],
      required: false
    },
    {
      title: 'Admin Commission',
      name: 'admin_commission',
      type: 'text',
      required: true
    },
    {
      title: 'Minimum Rent',
      name: 'minimum_rent',
      type: 'text',
      required: true
    },
    {
      title: 'hotel category image',
      name: 'system_property_category_image',
      type: 'file',
      required: true
    }
  ];

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();
  useEffect(() => {
    const getRegionList = async () => {
      setIsLoading(true);
      try {
        const res = await handleGetRegionList();

        if (res.status === 200) {
          const list = res?.data.result.map(({ name, id }) => ({
            value: id,
            label: name
          }));

          setRegionList(list);
        } else {
          console.error('Failed to fetch region list:', res.status);
        }
      } catch (error) {
        console.error('Error fetching region list:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getRegionList();
  }, []);
  const onSubmit = async data => {
    setIsLoading(true);

    const formData = new FormData();

    // Convert data to FormData
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    // Delete a specific item from FormData
    formData.delete('system_property_category_image');

    if (data.system_property_category_image) {
      for (let i = 0; i < data.system_property_category_image.length; i++) {
        formData.append(
          'system_property_category_image',
          data.system_property_category_image[i]
        );
      }
    }
    const res = await handleAddPropertyCategory(formData);
    if (res?.status === 200) {
      setIsLoading(false);
      reset();
    }
  };

  return {
    regionList,
    formArr,
    isLoading,
    control,
    errors,
    onSubmit,
    register,
    handleSubmit
  };
};
