import { GetUserInfo, UpdatePropertyStatus } from 'api-service/property-owner';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
export const usePropertyProfile = () => {
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState({});
  const [propertyImg, setPropertyImg] = useState([]);

  const handleGetUserInfo = async () => {
    const res = await GetUserInfo({ id });
    setUserInfo(res?.data?.result);
    const imgArr1 = res?.data?.result?.propertyDetails?.propertyImages || [];
    const imgArr = imgArr1.map(item => item.attachement);
    if (imgArr?.length > 1) {
      setPropertyImg(imgArr);
    }
  };

  const handlePropertyAction = async ({ id, type, status }) => {
    const data = {
      id,
      type,
      status
    };
    const res = await UpdatePropertyStatus(data);
    res?.status === 200 && handleGetUserInfo();
  };
  
  useEffect(() => {
    handleGetUserInfo();
  }, []);

  return {
    userInfo,
    propertyImg,
    handlePropertyAction,
  };
};
