import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import Main from './Main';

import 'helpers/initFA';
import { store } from 'store';

import 'react-toastify/dist/ReactToastify.min.css';
import './index.scss';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <>
    <Router>
      <Provider store={store}>
        <Main>
          <App />
        </Main>
        <ToastContainer />
      </Provider>
    </Router>
  </>
);
