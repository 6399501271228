import { useEffect, useState } from 'react';
import {
  handleDeleteRegionRecord,
  handleGetRegionList
} from 'api-service/region';
import useRangeParams from 'hooks/useRangeParams';

export const useRegionList = () => {
  const [regionList, setRegionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [deleteId, setDeleteId] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { params } = useRangeParams();

  const getRegionList = async () => {
    setIsLoading(true);
    const res = await handleGetRegionList(params);
    if (res?.status === 200) {
      setIsLoading(false);
      setRegionList(res?.data?.result);
    }
  };

  const handleDeleteRecord = async () => {
    setDeleteLoading(true);
    const res = await handleDeleteRegionRecord(deleteId);
    if (res?.status === 200) {
      getRegionList();
      setDeleteLoading(false);
      setOpenDelete(false);
    }
  };

  useEffect(() => {
    getRegionList();
  }, [params]);

  return {
    deleteLoading,
    regionList,
    isLoading,
    openDelete,
    setDeleteId,
    setOpenDelete,
    setDeleteLoading,
    handleDeleteRecord
  };
};
