import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import FalconComponentCard from 'components/common/FalconComponentCard';
import { useEditService } from './helper';

const AddService = () => {
  const {
    formArr,
    errors,
    setFormArr,
    onSubmit,
    register,
    handleSubmit,
    serviceType,
    setServiceType
  } = useEditService();

  const [perDayServicesList, setPerDayServicesList] = useState(
    serviceType.perDayServicesList
  );
  const [formPerDay, setFormPerDay] = useState(serviceType.perDayServicesList);
  const [defaultServiceType, setDefaultServiceType] = useState(
    serviceType.value
  );

  useEffect(() => {
    if (serviceType.perDayServicesList.length > 0) {
      setPerDayServicesList(serviceType.perDayServicesList);
      setFormPerDay(serviceType.perDayServicesList);
    } else {
      setPerDayServicesList([
        {
          name: '',
          capacity: '',
          destroy: 0,
          per_day_charges: '',
          id: 0,
          serviceCount: 0
        }
      ]);
      setFormPerDay(serviceType.perDayServicesList);
    }
    setDefaultServiceType(serviceType.value);
  }, []);

  useEffect(() => {
    if (formPerDay.length < 2 && serviceType.perDayServicesList.length > 0) {
      setPerDayServicesList(serviceType.perDayServicesList);
      setFormPerDay(serviceType.perDayServicesList);
    }
  }, [serviceType]);

  const handleChange = (e, formArr) => {
    let newFormArr = [];
    formArr.forEach(el => {
      if (el.name == e.target.name) {
        if (el.type == 'file') {
          el.file = e.target.files;
        } else {
          el.value = e.target.value;
        }
        newFormArr.push(el);
      } else {
        newFormArr.push(el);
      }
    });
    setFormArr(newFormArr);
  };

  const handlePerDayChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...perDayServicesList];
    list[index][name] = value;
    setPerDayServicesList(list);
    setServiceType({ ...serviceType, perDayServicesList: list });
  };

  const handleServiceTypeChange = event => {
    handleChange(event, formArr);
    const { value } = event.target;
    setServiceType({ ...serviceType, value: value });
  };

  const handleAddService = () => {
    setPerDayServicesList([
      ...perDayServicesList,
      {
        name: '',
        capacity: '',
        destroy: 0,
        per_day_charges: '',
        id: 0,
        serviceCount: 0
      }
    ]);
  };

  // Function to remove an item from the list
  const handleRemoveService = (index, id) => {
    let list = [...perDayServicesList];
    if (id != 0) {
      list[index]['destroy'] = 1;
    } else {
      list.splice(index, 1);
    }

    setPerDayServicesList(list);
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header title="Edit Service" light={false} />
      <FalconComponentCard.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            {formArr.map(({ name, title, type, value, required, list }) => {
              if (name == 'perDayServicesList') return null;
              return (
                <Col key={name} md="4">
                  {type !== 'select' && (
                    <Form.Group
                      onChange={e => handleChange(e, formArr)}
                      className="mb-3"
                      controlId="formHookname"
                    >
                      <Form.Label>
                        {title} {required && '*'}
                      </Form.Label>
                      <Form.Control
                        type={type}
                        name={name}
                        defaultValue={value}
                        placeholder={`Enter your ${title}`}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: required
                            ? `${title} is required field.`
                            : false
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  {type == 'file' && (
                    <img
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}${value}`}
                      width={45}
                      height={45}
                      alt="img"
                    />
                  )}

                  {type === 'select' && (
                    <Form.Group
                      onChange={e => handleChange(e, formArr)}
                      className="mb-3"
                      controlId="formHooknamsdsdsde"
                    >
                      <Form.Label>{title} *</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name={name}
                        value={value}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: required
                            ? `${title} is required field.`
                            : false
                        })}
                      >
                        <option value="">Select an option</option>
                        {list?.map(({ value, label }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              );
            })}
            {serviceType && (
              <Col md="4">
                <Form.Group
                  onChange={e => handleServiceTypeChange(e)}
                  className="mb-3"
                  controlId="formHooknamsdsdsde"
                >
                  <Form.Label>{serviceType.title} *</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name={serviceType.name}
                    disabled={
                      defaultServiceType == 'car_per_day' &&
                      formPerDay?.length > 0
                    }
                    isInvalid={!!errors?.[serviceType.name]}
                    value={serviceType.value}
                    {...register(serviceType.name, {
                      required: `${serviceType.title} is required field.`
                    })}
                  >
                    {serviceType.list?.map(({ value, label }) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors?.[serviceType.name] &&
                      errors?.[serviceType.name]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
            {serviceType.value == 'car_per_day' &&
              perDayServicesList.map((service, index) => (
                <div key={index}>
                  {!service.destroy == 1 && (
                    <Row className="mb-3 align-items-end">
                      <Col md="3">
                        <Form.Group controlId={`serviceName-${index}`}>
                          <Form.Label>Service Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={service.name}
                            onChange={e => handlePerDayChange(index, e)}
                            placeholder="Enter service name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group controlId={`serviceCapacity-${index}`}>
                          <Form.Label>Capacity</Form.Label>
                          <Form.Control
                            type="number"
                            name="capacity"
                            value={service.capacity}
                            onChange={e => handlePerDayChange(index, e)}
                            placeholder="Enter capacity"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group
                          controlId={`serviceper_day_charges-${index}`}
                        >
                          <Form.Label>Per Day Charges</Form.Label>
                          <Form.Control
                            type="number"
                            name="per_day_charges"
                            value={service.per_day_charges}
                            onChange={e => handlePerDayChange(index, e)}
                            placeholder="Enter Per Day Charges"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        {index !== 0 ? (
                          <>
                            {service.serviceCount < 1 && (
                              <Button
                                variant="danger"
                                onClick={() =>
                                  handleRemoveService(index, service.id)
                                }
                              >
                                Remove Service
                              </Button>
                            )}
                          </>
                        ) : (
                          <Button variant="primary" onClick={handleAddService}>
                            Add New Service
                          </Button>
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
              ))}
          </Row>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default AddService;
