import { useEffect, useState } from 'react';

import { handleGetPropertyOwnerList } from 'api-service/property-owner';
import useRangeParams from 'hooks/useRangeParams';

export const usePropertyOwnerList = () => {
  const [propertyOwnerArr, setPropertyOwnerArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { params } = useRangeParams();

  const getPropertyOwnerList = async () => {
    setIsLoading(true);
    const res = await handleGetPropertyOwnerList(params);
    if (res?.status === 200) {
      setPropertyOwnerArr(res?.data?.result?.propertyOwners);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPropertyOwnerList();
  }, [params]);

  return {
    propertyOwnerArr,
    isLoading
  };
};
