import { GetUserInfo, HandleUpdateVehicleStatus } from 'api-service/driver';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useDriverProfile = () => {
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState({});
  const [vehicleImg, setVehicleImg] = useState([]);

  const handleGetUserInfo = async () => {
    const res = await GetUserInfo({ id });
    setUserInfo(res?.data?.result);
    const imgArr = res?.data?.result?.vehicleDetails?.images || [];
    if (imgArr?.length > 1) {
      setVehicleImg(JSON.parse(imgArr));
    }
  };

  const handleVehicleAction = async ({ id, type, status }) => {
    const data = {
      id,
      type,
      status
    };
    const res = await HandleUpdateVehicleStatus(data);
    res?.status === 200 && handleGetUserInfo();
  };

  useEffect(() => {
    handleGetUserInfo();
  }, []);

  return {
    userInfo,
    vehicleImg,
    handleVehicleAction
  };
};
