import React from 'react';
import { Link } from 'react-router-dom';
import { useFakeUsers } from './helper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvancePagination from 'components/common/advance-table/AdvancePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Loading from 'components/loading';
import useExportTable from 'hooks/useExportTable';
import ActionButton from 'components/common/ActionButton';
import { Card, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { camelize } from '../../../helpers/utils';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';

const FakeUser = () => {
  const { isLoading, fakeUsers, metaList } = useFakeUsers();

  const columns = [
    {
      accessor: 'id',
      Header: '#'
    },
    {
      accessor: 'first_name',
      Header: 'First Name'
    },
    {
      accessor: 'last_name',
      Header: 'Last Name'
    },
    {
      accessor: 'email',
      Header: 'Email'
    },
    {
      accessor: 'phone_number',
      id: 'phone_number',
      Header: 'Phone No',
      Cell: ({ row }) =>
        `${row.original.dial_code} ${row.original.phone_number}`
    },
    {
      accessor: 'cnic',
      id: 'cnic',
      Header: 'CNIC',
      Cell: ({ row }) => <span>{row.original.cnic}</span>
    },
    {
      accessor: 'created_at',
      Header: 'Created At'
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: ({ row }) => <span>{row.original.status}</span>
    },
    {
      Header: 'Action',
      Cell: ({ row }) => (
        <div className="d-flex">
          <Link
            to={`/fake-user/${row.original.id}/ride-bookings`}
            className="p-0 me-2"
          >
            <ActionButton
              icon="car-side"
              title="View Ride Bookings"
              variant="action"
              className="p-0 me-2"
              onClick={() => {
                sessionStorage.setItem(
                  'driverRideBookings' + row.original.id,
                  JSON.stringify(row.original)
                );
              }}
            />
          </Link>
          <Link
            to={`/fake-user/${row.original.id}/hotel-bookings`}
            className="p-0 me-2"
          >
            <ActionButton
              icon="landmark"
              title="View Hotel Bookings"
              variant="action"
              className="p-0 me-2"
              onClick={() => {
                sessionStorage.setItem(
                  'driverHotelBookings' + row.original.id,
                  JSON.stringify(row.original)
                );
              }}
            />
          </Link>
        </div>
      )
    }
  ];

  function AdvanceTableExample() {
    return (
      <AdvanceTableWrapper
        columns={columns}
        data={fakeUsers}
        sortable
        pagination
        perPage={metaList.perPage}
        metaList={metaList}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvancePagination
            rowCount={fakeUsers.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
            metaList={metaList}
          />
        </div>
      </AdvanceTableWrapper>
    );
  }

  const selectedColumns = [
    'id',
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'status'
  ];
  const exportToExcel = useExportTable(
    fakeUsers,
    'fake-users.xlsx',
    selectedColumns
  );

  return (
    <Card>
      <Card.Header>
        <Flex justifyContent={'between'} alignItems={'center'}>
          <h5
            className="mb-0 hover-actions-trigger"
            id={camelize('Fake Users')}
          >
            {'Fake Users'}
            <HashLink
              to={`${location.pathname}#${camelize('Fake Users')}`}
              className="hover-actions ps-2"
              style={{ top: 0, right: '-25px' }}
            >
              #
            </HashLink>
          </h5>
          {!isLoading && exportToExcel}
        </Flex>
      </Card.Header>
      <Card.Body style={{ height: '500px', overflow: 'scroll' }}>
        {isLoading ? (
          <Loading className={'loadingTable'} />
        ) : (
          <AdvanceTableExample />
        )}
      </Card.Body>
    </Card>
  );
};

FakeUser.propTypes = {
  // Define prop type validations for the props mentioned in the error messages
  row: PropTypes.shape({
    original: PropTypes.shape({
      cnic: PropTypes.string,
      status: PropTypes.string,
      id: PropTypes.number
    })
  })
};

export default FakeUser;
