import { handleGetfakeUsers } from 'api-service/rider';
import { useEffect, useState } from 'react';
import useRangeParams from 'hooks/useRangeParams';
import useFetchPage from 'hooks/useFetchPage';

export const useFakeUsers = () => {
  const [fakeUsers, setfakeUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { params } = useRangeParams();
  const [metaList, setMetaList] = useState({});
  const { pagination } = useFetchPage();

  const getfakeUsers = async () => {
    setIsLoading(true);
    const res = await handleGetfakeUsers(params, pagination);
    if (res?.status === 200) {
      const fakeUserList = res?.data?.result?.data;
      setfakeUsers(fakeUserList);
      const metaTag = { ...res?.data?.result?.meta };
      metaTag.total = metaTag.total.lenght;
      setMetaList(metaTag);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getfakeUsers();
  }, [params, pagination]);

  return {
    fakeUsers,
    isLoading,
    metaList
  };
};
