import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import FalconComponentCard from 'components/common/FalconComponentCard';
import { useAddPropertyService } from './helper';

const AddServiceCategory = () => {
  const { isLoading, formArr, errors, onSubmit, register, handleSubmit } =
    useAddPropertyService();

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header title="Add hotel service" light={false} />
      <FalconComponentCard.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            {formArr.map(({ name, title, type, required }) => {
              return (
                <Col key={name} md="4">
                  <Form.Group className="mb-3" controlId="formHookname">
                    <Form.Label>
                      {title} {required && '*'}
                    </Form.Label>
                    <Form.Control
                      type={type}
                      name={name}
                      placeholder={`Enter your ${title}`}
                      isInvalid={!!errors?.[name]}
                      {...register(name, {
                        required: required
                          ? `${title} is required field.`
                          : false
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.[name] && errors?.[name]?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              );
            })}
          </Row>

          <Button variant="primary" type="submit" disabled={isLoading}>
            Submit
          </Button>
        </Form>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default AddServiceCategory;
