import React, { useRef } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import deleteIcon from 'assets/img/icons/remove.png';
import { useRideRequestLocation } from './helper';
import rickshawPin from 'assets/img/rickshaw.png';
import carPin from 'assets/img/car.png';
import bikePin from 'assets/img/bike.png';

const libraries = ['places', 'drawing'];

const RideRequestLocation = () => {
  const { location } = useRideRequestLocation();
  const mapRef = useRef();
  const drawingManagerRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries
  });

  const containerStyle = {
    width: '60%',
    height: '60vh'
  };

  const deleteIconStyle = {
    cursor: 'pointer',
    backgroundImage: `url(${deleteIcon})`,
    height: '24px',
    width: '24px',
    marginTop: '5px',
    backgroundColor: '#fff',
    position: 'absolute',
    top: '2px',
    left: '28%',
    zIndex: 99999
  };

  const onLoadMap = map => {
    mapRef.current = map;
  };

  const darkMapStyle = [
    {
      featureType: 'all',
      elementType: 'geometry.fill',
      stylers: [{ color: '#2c2c2c' }]
    }
  ];

  let centerLat =
    (Number(location?.driver_details?.latitude) +
      Number(location?.rider_detail?.latitude)) /
    2;
  let centerLng =
    (Number(location?.driver_details?.longitude) +
      Number(location?.rider_detail?.longitude)) /
    2;

  const pinLogo = id => {
    switch (id) {
      case 1:
        return bikePin;
      case 12:
        return rickshawPin;
      default:
        return carPin;
    }
  };

  return isLoaded ? (
    <div
      className="map-container"
      style={{ position: 'relative', display: 'flex' }}
    >
      {drawingManagerRef.current && (
        <div title="Delete shape" style={deleteIconStyle}></div>
      )}
      <GoogleMap
        zoom={8}
        center={{
          lat: centerLat,
          lng: centerLng
        }}
        onLoad={onLoadMap}
        mapContainerStyle={containerStyle}
        options={{ styles: darkMapStyle }}
      >
        <Marker
          icon={{
            url: pinLogo(location.service_id),
            scaledSize: new window.google.maps.Size(30, 30)
          }}
          position={{
            lat: Number(location?.driver_details?.latitude),
            lng: Number(location?.driver_details?.longitude)
          }}
        />
        <Marker
          icon={{
            url:
              process.env.REACT_APP_IMAGE_BASE_URL +
              location?.rider_detail?.profile_image,
            scaledSize: new window.google.maps.Size(25, 25)
          }}
          position={{
            lat: Number(location?.rider_detail?.latitude),
            lng: Number(location?.rider_detail?.longitude)
          }}
        />
      </GoogleMap>
    </div>
  ) : null;
};

export default RideRequestLocation;
