import axios from 'utils/axios';
import { DASHBOARD_ROUTE, REGION_LIST } from 'utils/axios/endpoints';

export const handleGetDashboardData = async (region = '') => {
  const res = await axios.get(DASHBOARD_ROUTE + `?region=${region}`);
  return res;
};

export const handleGetRegionList = async () => {
  const res = await axios.get(REGION_LIST);
  return res;
};
