import React from 'react';
import { Link } from 'react-router-dom';

import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ActionButton from 'components/common/ActionButton';

import { useRegionList } from './helper';
import DeleteConfirmModal from 'components/confirm-delete-modal';
import Loading from 'components/loading';
import useExportTable from 'hooks/useExportTable';

const RegionList = () => {
  const {
    openDelete,
    deleteLoading,
    setDeleteId,
    setOpenDelete,
    setDeleteLoading,
    handleDeleteRecord,
    isLoading,
    regionList
  } = useRegionList();

  const data = `const columns = [
  {
    accessor: 'id',
    Header: 'No'
  },
  {
    accessor: 'name',
    Header: 'Name'
  },
  {
    accessor: 'distance_unit',
    Header: 'Distance Unit'
  },
  {
    accessor: 'timezone',
    Header: 'Timezone'
  },
  {
    accessor: 'created_at',
    Header: 'Created At'
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ row }) =>\`\${row.original.status ? "Active" : "In-Active"} \`
  },
  {
    accessor: 'action',
    Header: 'Action',
    Cell: ({ row }) => (
        <>
          <ActionButton icon="trash" onClick={() => {setDeleteId(row.original.id); setOpenDelete(true);}} title="delete" variant="action" className="p-0 me-2" />
          <Link to={\`/region/\${row.original.id}/edit\`} className="p-0 me-2">
            <ActionButton icon="pencil" onClick={() => {sessionStorage.setItem("regionDetail" + row.original.id, JSON.stringify(row.original))}} title="Edit" variant="action" className="p-0 me-2" />
          </Link>
        </>
      )
  }

];

  const data = ${JSON.stringify(regionList, null, 2)}`;

  const searchableTableCode = `${data}

  function AdvanceTableExample() {

    return (
      <>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
      {
        openDelete && <DeleteConfirmModal deleteModalOpen={openDelete}
          deleteLoading={deleteLoading}
          setDeleteId={setDeleteId}
          handleDeleteRow={handleDeleteRecord}
          setDeleteModalOpen={setOpenDelete}/>
      }
      </>
    );
  }

  render(isLoading ? <Loading className={"loadingTable"} /> : <AdvanceTableExample />)`;

  const exportColumns = ['id', 'name', 'distance_unit', 'timezone'];
  const exportToExcel = useExportTable(
    regionList,
    'regionList.xlsx',
    exportColumns
  );

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header
        title="Region List"
        light={false}
        className="border-bottom border-200"
        exportButton={regionList?.length > 0 ? exportToExcel : null}
        isLoading={isLoading}
      />
      <FalconComponentCard.Body
        code={searchableTableCode}
        scope={{
          AdvanceTableFooter,
          AdvanceTableWrapper,
          AdvanceTableSearchBox,
          setDeleteId,
          Link,
          isLoading,
          openDelete,
          ActionButton,
          AdvanceTable,
          deleteLoading,
          DeleteConfirmModal,
          Loading,
          setOpenDelete,
          setDeleteLoading,
          handleDeleteRecord
        }}
        language="jsx"
        noInline
        noLight
      />
    </FalconComponentCard>
  );
};

export default RegionList;
