import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import FalconComponentCard from 'components/common/FalconComponentCard';
import { useAddService } from './helper';

const AddService = () => {
  const {
    formArr,
    errors,
    onSubmit,
    register,
    handleSubmit,
    serviceType,
    setServiceType
  } = useAddService();
  const [perDayServicesList, setPerDayServicesList] = useState([
    { name: '', capacity: '', per_day_charges: '' }
  ]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...perDayServicesList];
    list[index][name] = value;
    setPerDayServicesList(list);
    setServiceType({ ...serviceType, perDayServicesList: list });
  };

  const handleServiceTypeChange = event => {
    const { value } = event.target;
    setServiceType({ ...serviceType, value: value });
  };

  // Function to add a new item to the list
  const handleAddService = () => {
    setPerDayServicesList([
      ...perDayServicesList,
      { name: '', capacity: '', per_day_charges: '' }
    ]);
  };

  // Function to remove an item from the list
  const handleRemoveService = index => {
    const list = [...perDayServicesList];
    list.splice(index, 1);
    setPerDayServicesList(list);
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header title="Add Ride Category" light={false} />
      <FalconComponentCard.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            {formArr.map(({ name, title, type, required, list }) => {
              return (
                <Col key={name} md="4">
                  {type !== 'select' && (
                    <Form.Group className="mb-3" controlId="formHookname">
                      <Form.Label>
                        {title} {required && '*'}
                      </Form.Label>
                      <Form.Control
                        type={type}
                        name={name}
                        placeholder={`Enter your ${title}`}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: required
                            ? `${title} is required field.`
                            : false
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                  {type === 'select' && (
                    <Form.Group className="mb-3" controlId="formHooknamsdsdsde">
                      <Form.Label>{title} *</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name={name}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: `${title} is required field.`
                        })}
                      >
                        <option value="">Select an option</option>
                        {list?.map(({ value, label }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              );
            })}
            {serviceType && (
              <Col md="4">
                <Form.Group
                  onChange={e => handleServiceTypeChange(e)}
                  className="mb-3"
                  controlId="formHooknamsdsdsde"
                >
                  <Form.Label>{serviceType.title} *</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name={serviceType.name}
                    isInvalid={!!errors?.[serviceType.name]}
                    value={serviceType.value}
                    {...register(serviceType.name, {
                      required: `${serviceType.title} is required field.`
                    })}
                  >
                    <option value="">Select an option</option>
                    {serviceType.list?.map(({ value, label }) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors?.[serviceType.name] &&
                      errors?.[serviceType.name]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
            {serviceType.value == 'car_per_day' &&
              perDayServicesList.map((service, index) => (
                <div key={index}>
                  <Row className="mb-3 align-items-end">
                    <Col md="3">
                      <Form.Group controlId={`serviceName-${index}`}>
                        <Form.Label>Service Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={service.name}
                          onChange={e => handleChange(index, e)}
                          placeholder="Enter service name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group controlId={`serviceCapacity-${index}`}>
                        <Form.Label>Capacity</Form.Label>
                        <Form.Control
                          type="number"
                          name="capacity"
                          value={service.capacity}
                          onChange={e => handleChange(index, e)}
                          placeholder="Enter capacity"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group controlId={`serviceper_day_charges-${index}`}>
                        <Form.Label>Per Day Charges</Form.Label>
                        <Form.Control
                          type="number"
                          name="per_day_charges"
                          value={service.per_day_charges}
                          onChange={e => handleChange(index, e)}
                          placeholder="Enter Per Day Charges"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      {index !== 0 ? (
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveService(index)}
                        >
                          Remove Service
                        </Button>
                      ) : (
                        <Button variant="primary" onClick={handleAddService}>
                          Add New Service
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
          </Row>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default AddService;
