import { handleUpdateRegion } from 'api-service/region';
import { timezones } from 'data/events/timezones';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

export const useEditRider = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [coordinates, setCoordinates] = useState('');
  const { id } = useParams();
  const [formArr, setFormArr] = useState([
    {
      title: 'Name',
      name: 'name',
      type: 'text',
      required: false
    },
    {
      title: 'Distance Unit',
      name: 'distance_unit',
      type: 'select',
      listArr: [
        { value: 'km', label: 'KM' },
        { value: 'mile', label: 'Mile' }
      ],
      required: false
    },
    {
      title: 'Timezone',
      name: 'timezone',
      type: 'select',
      listArr: timezones,
      required: false
    },
    {
      title: 'Status',
      name: 'status',
      type: 'select',
      listArr: [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' }
      ],
      required: false
    }
  ]);

  const handleRegionData = () => {
    const regionDetail = JSON.parse(
      sessionStorage.getItem(`regionDetail${id}`)
    );
    setCoordinates(regionDetail.coordinates.coordinates[0]);
    setFormArr([
      {
        title: 'Name',
        name: 'name',
        value: regionDetail.name,
        type: 'text',
        required: false
      },
      {
        title: 'Distance Unit',
        name: 'distance_unit',
        value: regionDetail.distance_unit,
        type: 'select',
        listArr: [
          { value: 'km', label: 'KM' },
          { value: 'mile', label: 'Mile' }
        ],
        required: false
      },
      {
        title: 'Timezone',
        name: 'timezone',
        value: regionDetail.timezone,
        type: 'select',
        listArr: timezones,
        required: false
      },
      {
        title: 'Status',
        name: 'status',
        value: regionDetail.status ? 1 : 0,
        type: 'select',
        listArr: [
          { value: '1', label: 'Active' },
          { value: '0', label: 'Inactive' }
        ],
        required: false
      }
    ]);
  };

  useEffect(() => {
    handleRegionData();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = async () => {
    setIsLoading(true);
    let bodyData = {};
    formArr.forEach(el => {
      bodyData[el.name] = el.value;
    });
    bodyData.coordinates = coordinates;
    await handleUpdateRegion(bodyData, id);
    setIsLoading(false);
    navigate('/region-list');
  };

  return {
    formArr,
    control,
    errors,
    isLoading,
    setCoordinates,
    onSubmit,
    register,
    handleSubmit,
    coordinates,
    setFormArr
  };
};
