import React from 'react';
import PropTypes from 'prop-types';

import style from './loading.module.scss';

const Loading = ({ className }) => {
  return (
    <div className={`${style.loader} ${className}`} id="loader">
      <div></div>
      <div></div>
    </div>
  );
};
export default Loading;

Loading.propTypes = {
  className: PropTypes.string
};
