import axios from 'axios';
import { createNotification } from 'common';
import { storeReset } from 'store';
import { store } from 'store';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  req => {
    const state = store.getState();
    if (state?.app?.token) {
      req.headers.Authorization = `Bearer ${state?.app?.token}`;
    }

    return req;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  res => {
    if (['post', 'put', 'delete'].includes(res?.config?.method.toLowerCase())) {
      createNotification('success', 'Success');
    }
    return res;
  },
  error => {
    createNotification('error', error?.response?.data?.message);

    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      window.location.replace('/auth/login');
      store.dispatch(storeReset());
    }

    return error;
  }
);
export default axios;
