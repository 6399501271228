import React from 'react';
import { Tab } from 'react-bootstrap';
import ChatContentHeader from './ChatContentHeader';
import ChatContentBody from './ChatContentBody';
import MessageTextArea from './MessageTextArea';
import { useAppSelector } from 'store/hook';

const ChatContent = () => {
  const { chatThread } = useAppSelector(state => state?.app);
  return (
    <Tab.Content className="card-chat-content">
      {chatThread.map((thread, index) => (
        <Tab.Pane key={index} eventKey={index} className="card-chat-pane">
          <ChatContentHeader thread={thread} />
          <ChatContentBody thread={thread} />
        </Tab.Pane>
      ))}
      <MessageTextArea />
    </Tab.Content>
  );
};

export default ChatContent;
