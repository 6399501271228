import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useFetchPage = () => {
  const [filterParams, setFilterParams] = useState('');
  const location = useLocation();

  useEffect(() => {
    let rangeParams = new URLSearchParams(location.search);

    if (rangeParams.has('page') || rangeParams.has('limit')) {
      setFilterParams(
        `?page=${rangeParams.get('page')}&limit=${rangeParams.get('limit')}`
      );
    }

    return () => setFilterParams('');
  }, [location.search]);

  return { pagination: filterParams };
};

export default useFetchPage;
