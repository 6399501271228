import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ChatThread from './ChatThread';
import { useAppSelector } from 'store/hook';
import { useState } from 'react';

const ChatSidebar = ({ hideSidebar }) => {
  const { chatThread } = useAppSelector(state => state?.app);
  const [searchInput, setSearchInput] = useState('');

  const filteredUsers = chatThread.filter(user =>
    user.name?.toLowerCase().includes(searchInput)
  );

  return (
    <div className={classNames('chat-sidebar', { 'start-0': hideSidebar })}>
      <div className="contacts-list">
        <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
          <Nav className="border-0">
            {filteredUsers?.map((ele, index) => (
              <ChatThread userInfo={ele} index={index} key={`${ele.id + 1}`} />
            ))}
          </Nav>
        </SimpleBarReact>
      </div>
      <div className="contacts-search-wrapper">
        <div className="contacts-search-wrapper mb-0 position-relative d-md-none d-lg-block w-100 h-100">
          <input
            className="chat-contacts-search border-0 h-100"
            placeholder="Search contacts ..."
            onChange={e => setSearchInput(e.target.value?.toLowerCase())}
            style={{ border: 'none', outline: 'none', width: '100%' }}
            size="sm"
          />
          <FontAwesomeIcon icon="search" className="contacts-search-icon" />
        </div>
      </div>
    </div>
  );
};

ChatSidebar.propTypes = {
  hideSidebar: PropTypes.bool
};

export default ChatSidebar;
