import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Card, Tab, Row, Col } from 'react-bootstrap';

import FalconCardBody from './FalconCardBody';
import Flex from './Flex';
import { camelize } from '../../helpers/utils';

const FalconComponentCardHeader = ({
  light,
  className,
  title,
  exportButton,
  isLoading,
  children
}) => {
  const location = useLocation();

  return (
    <Card.Header className={classNames({ 'bg-light': light }, className)}>
      <Row className="align-items-end g-2">
        <Col>
          {title && (
            <Flex justifyContent={'between'} alignItems={'center'}>
              <h5 className="mb-0 hover-actions-trigger" id={camelize(title)}>
                {title}
                <HashLink
                  to={`${location.pathname}#${camelize(title)}`}
                  className="hover-actions ps-2"
                  style={{ top: 0, right: '-25px' }}
                >
                  #
                </HashLink>
              </h5>
              {!isLoading && exportButton}
            </Flex>
          )}
          {children}
        </Col>
      </Row>
    </Card.Header>
  );
};

const FalconComponentCard = ({
  children,
  multiSections,
  noGuttersBottom,
  ...rest
}) => {
  return (
    <Card
      style={{ minHeight: '400px' }}
      className={classNames({ 'mb-3': !noGuttersBottom })}
      {...rest}
    >
      {multiSections ? (
        children
      ) : (
        <Tab.Container defaultActiveKey="preview">{children}</Tab.Container>
      )}
    </Card>
  );
};

FalconComponentCard.Header = FalconComponentCardHeader;
FalconComponentCard.Body = FalconCardBody;

FalconComponentCard.propTypes = {
  children: PropTypes.node,
  multiSections: PropTypes.bool,
  noGuttersBottom: PropTypes.bool
};

FalconComponentCardHeader.propTypes = {
  light: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  exportButton: PropTypes.node,
  isLoading: PropTypes.bool
};

export default FalconComponentCard;
