import React from 'react';
import * as XLSX from 'xlsx';
import { Button } from 'react-bootstrap';

const useExportTable = (data, fileName, selectedColumns) => {
  const exportToExcel = () => {
    const dataForSheet = [selectedColumns].concat(
      data.map(row =>
        selectedColumns.map(column => {
          if (column.includes('.')) {
            const associatedColumn = column.split('.');
            return row[associatedColumn[0]][associatedColumn[1]];
          } else {
            return row[column];
          }
        })
      )
    );

    const ws = XLSX.utils.aoa_to_sheet(dataForSheet);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    XLSX.writeFile(wb, fileName);
  };

  const exportButton = (
    <Button
      variant="secondary"
      className="btn btn-sm m-2 hover-text-decoration-none"
      onClick={exportToExcel}
    >
      Export Excel
    </Button>
  );

  return exportButton;
};

export default useExportTable;
