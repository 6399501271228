import {
  handleGetPropertyRequestList,
  handleUpdatePropertyRequestList
} from 'api-service/property-request';
import { handleRefundRequestList } from 'api-service/refund';
import { useEffect, useState } from 'react';
import useRangeParams from 'hooks/useRangeParams';
import useFetchPage from 'hooks/useFetchPage';

export const usePropertyRequestList = () => {
  const [propertyRequestList, setPropertyRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { params } = useRangeParams();
  const [metaList, setMetaList] = useState([]);
  const { pagination } = useFetchPage();

  const getPropertyRequestList = async () => {
    setIsLoading(true);
    const res = await handleGetPropertyRequestList(params, pagination);
    if (res?.status === 200) {
      const propertyRequestList = res?.data?.result?.data;
      setMetaList(res?.data?.result?.meta);
      setPropertyRequestList(propertyRequestList);
      setIsLoading(false);
    }
  };

  const updatePropertyRequestList = async (id, status) => {
    await handleUpdatePropertyRequestList(id, status);
    const updatedStatus = status;
    const newArr = [];
    propertyRequestList.forEach(el => {
      if (el.id == id) {
        el.status = updatedStatus.status;
        newArr.push(el);
      } else {
        newArr.push(el);
      }
    });
    setPropertyRequestList(newArr);
  };

  const refundPropertyRequestList = async body => {
    await handleRefundRequestList(body);
  };

  useEffect(() => {
    getPropertyRequestList();
  }, [params, pagination]);

  return {
    propertyRequestList,
    isLoading,
    updatePropertyRequestList,
    refundPropertyRequestList,
    metaList
  };
};
