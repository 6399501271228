import React from 'react';
import { Link } from 'react-router-dom';
import { useDriverList } from './helper';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ActionButton from 'components/common/ActionButton';
import Loading from 'components/loading';
import useExportTable from 'hooks/useExportTable';

const DriverList = () => {
  const { driverArr, isLoading } = useDriverList();

  const data = `const columns = [
  {
    accessor: 'id',
    Header: 'No'
  },
  {
    accessor: 'first_name',
    Header: 'First Name'
  },
  {
    accessor: 'last_name',
    Header: 'Last Name'
  },
  {
    accessor: 'email',
    Header: 'Email'
  },
  {
    accessor: 'phone_number',
    id: 'phone_number',
    Header: 'Phone No',
    Cell: ({ row }) =>\`\${row.original.dial_code} \${row.original.phone_number}\`
  },
  {
    accessor: 'created_at',
    Header: 'Created At'
  },
  {
    Header: 'Action',
    Cell: ({ row }) => (
      <>
        <Link to={\`/driver-details/\${row.original.id}\`} className="p-0 me-2">
          <ActionButton icon="eye" title="View" variant="action" className="p-0 me-2" />
        </Link>
        <Link to={\`/driver/\${row.original.id}/ride-bookings\`} className="p-0 me-2">
          <ActionButton icon="car-side" title="View Ride Bookings" variant="action" className="p-0 me-2" />
        </Link>
      </>
    )
  },
];

const data = ${JSON.stringify(driverArr, null, 2)}`;

  const searchableTableCode = `${data}

  function AdvanceTableExample() {

    return (
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            className: 'fs--1 mb-0 overflow-hidden',
            userTable: true
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    );
  }

  render(isLoading ? <Loading className={"loadingTable"} /> : <AdvanceTableExample />)`;

  const exportColumns = [
    'id',
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'status'
  ];
  const exportToExcel = useExportTable(
    driverArr,
    'driverArr.xlsx',
    exportColumns
  );

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header
        title="Driver List"
        light={false}
        className="border-bottom border-200"
        exportButton={driverArr?.length > 0 ? exportToExcel : null}
        isLoading={isLoading}
      />
      <FalconComponentCard.Body
        code={searchableTableCode}
        scope={{
          AdvanceTableSearchBox,
          AdvanceTableWrapper,
          AdvanceTableFooter,
          AdvanceTable,
          ActionButton,
          Link,
          Loading,
          isLoading
        }}
        language="jsx"
        noInline
        noLight
      />
    </FalconComponentCard>
  );
};

export default DriverList;
