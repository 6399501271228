import React from 'react';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Flex from 'components/common/Flex';

const WeeklySales = ({ data, amountClassName }) => {
  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">{data.name}</h6>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
          <h2
            className={classNames(
              amountClassName,
              'mb-1 text-700 fw-normal lh-1'
            )}
          >
            {data.value}
          </h2>
        </div>
      </Card.Body>
    </Card>
  );
};

WeeklySales.propTypes = {
  data: PropTypes.object,
  amountClassName: PropTypes.string
};

export default WeeklySales;
