import { handleUpdatePropertyCategory } from 'api-service/system-property-category';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { handleGetRegionList } from 'api-service/services';

export const useEditCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [formArr, setFormArr] = useState([
    {
      title: 'Name',
      name: 'name',
      value: '',
      type: 'text',
      required: true
    },
    {
      title: 'Region',
      name: 'region_id',
      value: '',
      type: 'select',
      required: false,
      list: regionList
    },
    {
      title: 'Last Name',
      name: 'sub_name',
      value: '',
      type: 'text',
      required: true
    },
    {
      title: 'hotel category Image',
      name: 'system_property_category_image',
      value: '',
      type: 'file',
      required: true
    }
  ]);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const handleCategoryData = () => {
    const categoryDetail = JSON.parse(
      sessionStorage.getItem(`categoryDetail${id}`)
    );
    setFormArr([
      {
        title: 'Name',
        name: 'name',
        value: categoryDetail.name,
        type: 'text'
      },
      {
        title: 'Region',
        name: 'region_id',
        value: categoryDetail.region_id,
        type: 'select',
        required: false,
        list: regionList
      },
      {
        title: 'Sub Name',
        name: 'sub_name',
        value: categoryDetail.sub_name,
        type: 'text'
      },
      {
        title: 'Commission Type',
        name: 'commission_type',
        value: categoryDetail.commission_type,
        type: 'select',
        list: [
          {
            value: 'percent',
            label: 'Percent'
          }
        ],
        required: false
      },
      {
        title: 'Admin Commission',
        name: 'admin_commission',
        value: categoryDetail.admin_commission,
        type: 'text'
      },
      {
        title: 'Minimum Rent',
        name: 'minimum_rent',
        value: categoryDetail.minimum_rent,
        type: 'text'
      },
      {
        title: 'hotel category image',
        name: 'system_property_category_image',
        value: categoryDetail.image,
        type: 'file'
      }
    ]);
  };

  useEffect(() => {
    const getRegionList = async () => {
      const res = await handleGetRegionList();

      if (res.status === 200) {
        const list = res?.data.result.map(({ name, id }) => ({
          value: id,
          label: name
        }));

        setRegionList(list);
        setFormArr(prevFormArr =>
          prevFormArr.map(field =>
            field.name === 'region_id' ? { ...field, list } : field
          )
        );
      }
    };

    getRegionList();
    handleCategoryData();
  }, []);

  const onSubmit = async () => {
    setIsLoading(true);

    const formData = new FormData();

    // Convert data to FormData
    let bodyData = {};
    formArr.forEach(el => {
      if (el.type == 'file') {
        bodyData[el.name] = el.file;
      } else {
        bodyData[el.name] = el.value;
      }
    });

    Object.keys(bodyData).forEach(key => {
      formData.append(key, bodyData[key]);
    });

    // Delete a specific item from FormData
    formData.delete('system_property_category_image');

    if (bodyData.system_property_category_image) {
      for (let i = 0; i < bodyData.system_property_category_image.length; i++) {
        formData.append(
          'system_property_category_image',
          bodyData.system_property_category_image[i]
        );
      }
    }

    const res = await handleUpdatePropertyCategory(formData, id);
    if (res?.status === 200) {
      setIsLoading(false);
    }
    navigate('/system-property-category-list');
  };

  return {
    formArr,
    isLoading,
    control,
    errors,
    onSubmit,
    register,
    handleSubmit,
    setFormArr
  };
};
