import { createSlice } from '@reduxjs/toolkit';
import { settings } from 'config';
import { setItemToStore } from 'helpers/utils';

const initialState = {
  token: localStorage.getItem('token') || '',
  isLoading: false,
  userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
  listRider: [],
  chatThread: [],
  receiverId: '',
  messages: [],
  scrollToBottom: true,
  isChatLoading: true
};

export const appSlice = createSlice({
  name: 'rmt',
  initialState,
  reducers: {
    // setter method for token
    setToken: (state, { payload: { token } }) => {
      state.token = token;
      localStorage.setItem('token', token);
    },
    setIsLoading: (state, { payload: { isLoading } }) => {
      state.isLoading = isLoading;
    },
    setIsChatLoading: (state, { payload: { isChatLoading } }) => {
      state.isChatLoading = isChatLoading;
    },
    setUserInfo: (state, { payload: { userInfo } }) => {
      state.userInfo = userInfo;
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    // setter method for token
    setListRider: (state, { payload }) => {
      state.listRider = payload;
    },
    setReceiverId: (state, { payload }) => {
      state.receiverId = payload;
    },
    setMessages: (state, { payload }) => {
      state.messages = payload;
    },
    setScrollToBottom: (state, { payload }) => {
      state.scrollToBottom = payload;
    },

    // setter method for chat section
    setChatThread: (state, { payload: { chatThread } }) => {
      state.chatThread = chatThread;
      state.receiverId = chatThread[0].id;
    },

    // config
    setConfig: (state, action) => {
      const { type, payload } = action;
      switch (type) {
        case 'SET_CONFIG':
          if (payload.setInStore) {
            setItemToStore(payload.key, payload.value);
          }
          return {
            ...state,
            [payload.key]: payload.value
          };
        case 'REFRESH':
          return {
            ...state
          };
        case 'RESET':
          localStorage.clear();
          return {
            ...state,
            ...settings
          };
        default:
          return state;
      }
    }
  }
});
