import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import FalconComponentCard from 'components/common/FalconComponentCard';
import { useAddCategory } from './helper';

const AddPropertyCategory = () => {
  const { isLoading, formArr, errors, onSubmit, register, handleSubmit } =
    useAddCategory();

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header title="Add hotel category" light={false} />
      <FalconComponentCard.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            {formArr.map(({ name, title, type, required, list }) => {
              return (
                <Col key={name} md="4">
                  {type !== 'select' && (
                    <Form.Group className="mb-3" controlId="formHookname">
                      <Form.Label>
                        {title} {required && '*'}
                      </Form.Label>
                      <Form.Control
                        type={type}
                        name={name}
                        placeholder={`Enter your ${title}`}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: required
                            ? `${title} is required field.`
                            : false
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                  {type === 'select' && (
                    <Form.Group className="mb-3" controlId="formHooknamsdsdsde">
                      <Form.Label>{title} *</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name={name}
                        isInvalid={!!errors?.[name]}
                        {...register(name, {
                          required: `${title} is required field.`
                        })}
                      >
                        <option value="">Select an option</option>
                        {list?.map(({ value, label }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors?.[name] && errors?.[name]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              );
            })}
          </Row>
          <Button variant="primary" type="submit" disabled={isLoading}>
            Submit
          </Button>
        </Form>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default AddPropertyCategory;
