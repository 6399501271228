import {
  handleGetUsers,
  handleGetRoles,
  handleAddNotification
} from 'api-service/notification';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useNotification = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [recipientType, setRecipientType] = useState('general');
  const [selectedUser, setSelectedUser] = useState(null);
  const [formArr, setFormArr] = useState([
    {
      title: 'Title',
      name: 'title',
      type: 'text',
      value: '',
      required: true
    },
    {
      title: 'Description',
      name: 'description',
      type: 'textarea',
      value: ''
    },
    {
      title: 'Type',
      name: 'type',
      type: 'text',
      value: '',
      required: true
    }
  ]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const fetchUserList = async () => {
    const res = await handleGetUsers();
    if (res?.status === 200) {
      setUserList(res?.data?.result);
    }
    const roleRes = await handleGetRoles();
    if (roleRes?.status === 200) {
      setRoleList(roleRes?.data?.result);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const onSubmit = async () => {
    setIsLoading(true);

    const bodyData = {
      sending_type: recipientType
    };
    formArr.forEach(el => {
      bodyData[el.name] = el.value;
    });
    if (recipientType !== 'general') {
      recipientType == 'specific'
        ? (bodyData.user_id = selectedUser.value)
        : (bodyData.role_id = selectedUser.value);
    }
    const res = await handleAddNotification(bodyData);
    if (res?.status === 200) {
      setIsLoading(false);
    }
    navigate('/list-notification');
  };

  return {
    formArr,
    isLoading,
    control,
    errors,
    onSubmit,
    register,
    handleSubmit,
    setFormArr,
    userList,
    recipientType,
    setRecipientType,
    roleList,
    setSelectedUser
  };
};
