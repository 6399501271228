import { handleBookings } from 'api-service/booking';
import { handleUpdateRideRequestList } from 'api-service/ride-request';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handleRefundRequestList } from 'api-service/refund';
import useRangeParams from 'hooks/useRangeParams';
import useFetchPage from 'hooks/useFetchPage';

export const useBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { params } = useRangeParams();
  const { id } = useParams();
  const [metaList, setMetaList] = useState([]);
  const { pagination } = useFetchPage();
  const [isRide, setIsRide] = useState(
    location.pathname.includes('ride-bookings')
  );

  const getBookings = async () => {
    const pathname = location.pathname;
    const type = pathname.includes('driver')
      ? 'driver'
      : pathname.includes('user')
      ? 'user'
      : 'property_owner';
    setIsLoading(true);
    const res = await handleBookings(params, pagination, id, isRide, type);

    if (res?.status === 200) {
      const requestDataList = res?.data?.result?.data;
      setMetaList(res?.data?.result?.meta);
      setBookings(requestDataList);
      setIsLoading(false);
    }
  };

  const updateRideRequestList = async (id, status) => {
    await handleUpdateRideRequestList(id, status);
    const updatedStatus = status;
    const newArr = [];
    bookings.forEach(el => {
      if (el.id == id) {
        el.status = updatedStatus.status;
        newArr.push(el);
      } else {
        newArr.push(el);
      }
    });
    setBookings(newArr);
  };

  const refundBooking = async body => {
    await handleRefundRequestList(body);
  };

  useEffect(() => {
    if (location.pathname.includes('ride-bookings')) {
      getBookings();
      setIsRide(isRide);
    } else {
      getBookings();
    }
  }, [params, pagination]);

  return {
    isLoading,
    bookings,
    updateRideRequestList,
    refundBooking,
    metaList,
    isRide
  };
};
