import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { handleAddService, handleGetRegionList } from 'api-service/services';

export const useAddService = () => {
  const navigate = useNavigate();
  const [regionList, setRegionList] = useState([]);
  const [serviceType, setServiceType] = useState({
    title: 'Service Type',
    name: 'service_type',
    type: 'select',
    required: true,
    value: '',
    list: [
      { label: 'Car', value: 'car' },
      { label: 'Car Per Day', value: 'car_per_day' },
      { label: 'Bike', value: 'bike' },
      { label: 'Rickshaw', value: 'rickshaw' }
    ],
    perDayServicesList: [{ name: '', capacity: '', per_day_charges: '' }]
  });

  const formArr = [
    {
      title: 'Name',
      name: 'name',
      type: 'text',
      required: true
    },
    {
      title: 'Region',
      name: 'region_id',
      type: 'select',
      required: true,
      list: regionList
    },
    {
      title: 'Capacity',
      name: 'capacity',
      type: 'number',
      required: true
    },
    {
      title: 'Base Fare',
      name: 'base_fare',
      type: 'number',
      required: true
    },
    {
      title: 'Minimum Fare',
      name: 'minimum_fare',
      type: 'number',
      required: true
    },
    {
      title: 'Minimum Distance',
      name: 'minimum_distance',
      type: 'number',
      required: true
    },
    {
      title: 'Per Distance',
      name: 'per_distance',
      type: 'number',
      required: true
    },
    {
      title: 'Per minute Drive',
      name: 'per_minute_drive',
      type: 'number',
      required: true
    },
    {
      title: 'Time Limit',
      name: 'time_limit',
      type: 'number',
      required: true
    },
    {
      title: 'Per Minute Wait',
      name: 'per_minute_wait',
      type: 'number',
      required: true
    },
    {
      title: 'Payment Method',
      name: 'payment_method',
      type: 'select',
      list: [
        {
          value: 'cash',
          label: 'Cash'
        }
      ],
      required: true
    },
    {
      title: 'Commission Type',
      name: 'commission_type',
      type: 'select',
      list: [
        {
          value: 'percent',
          label: 'Percent'
        }
      ],
      required: false
    },
    {
      title: 'Admin Commission',
      name: 'admin_commission',
      type: 'number',
      required: true
    },
    {
      title: 'Image',
      name: 'service_image',
      type: 'file',
      required: true
    },
    {
      title: 'Description',
      name: 'description',
      type: 'text',
      required: false
    }
  ];

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    const getRegionList = async () => {
      const res = await handleGetRegionList();

      if (res.status === 200) {
        const list = res?.data.result.map(({ name, id }) => ({
          value: id,
          label: name
        }));

        setRegionList(list);
      }
    };

    getRegionList();
  }, []);

  const onSubmit = async data => {
    let payload = { ...data };
    const formData = new FormData();

    // Convert data to FormData
    Object.keys(payload).forEach(key => {
      formData.append(key, payload[key]);
    });

    // Delete a specific item from FormData
    formData.delete('service_image');

    if (payload.service_type == 'car_per_day') {
      formData.append(
        `per_day_services_list`,
        JSON.stringify(serviceType.perDayServicesList)
      );
    }
    if (payload.service_image) {
      for (let i = 0; i < payload.service_image.length; i++) {
        formData.append('service_image', payload.service_image[i]);
      }
    }

    const res = await handleAddService(formData);
    res?.status === 200 && reset();

    navigate('/service-list');
  };

  return {
    regionList,
    formArr,
    control,
    errors,
    onSubmit,
    register,
    handleSubmit,
    serviceType,
    setServiceType
  };
};
