import {
  faCar,
  faRoad,
  faCarSide,
  faSliders,
  faEnvelope,
  // faCommentDots,
  faAddressCard,
  faMapMarkerAlt,
  faLayerGroup,
  faLandmark
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import Login from 'pages/auth/login';
import Dashboard from 'pages/dashboard';
// import AddRider from 'pages/rider/add-rider';
import RiderList from 'pages/rider/rider-list';
import FakeUser from 'pages/fakeUser/fake-user';
import FakeUserBookings from 'pages/fakeUser/bookings';
import AddRegion from 'pages/region/add-region';
import DriverList from 'pages/driver/driver-list';
import PropertyOwnerList from 'pages/property-owner/property-owner-list';
import RegionList from 'pages/region/region-list';
import RegionEdit from 'pages/region/region-edit';
import AddService from 'pages/services/add-service';
import ServiceList from 'pages/services/services-list';
import EditService from 'pages/services/edit-service';
import RoleList from 'pages/account-setting/role-list';
import PermissionList from 'pages/account-setting/permission-list';
import RideRequestList from 'pages/ride-request/ride-request-list';
import RideRequestProfile from 'pages/ride-request/ride-request-profile';
import PropertyRequestList from 'pages/property-request/property-request-list';
import PropertyRequestProfile from 'pages/property-request/property-request-profile';
import Chat from 'components/chat/Chat';
import Profile from 'pages/driver/driver-profile';
import Bookings from 'pages/bookings';
import PropertyOwnerProfile from 'pages/property-owner/property-owner-profile';
import PropertyCategory from 'pages/system-property-category/property-category-list';
import PropertyServiceList from 'pages/system-property-services/property-service-list';
import AddPropertyCategory from 'pages/system-property-category/add-property-category';
import EditPropertyCategory from 'pages/system-property-category/edit-property-category';
import AddServiceCategory from 'pages/system-property-services/add-property-service';
import EditServiceCategory from 'pages/system-property-services/edit-property-service';
import MapLocation from 'pages/map-location/map-location';
import PropertyRequestLocation from 'pages/property-request/property-request-location';
import RideRequestLocation from 'pages/ride-request/ride-request-location';
import ListNotification from 'pages/notifications/list-notification';
import AddNotification from 'pages/notifications/add-notification';

export const dashboardRoutes = [
  {
    label: 'Dashboard',
    labelDisable: true,
    children: [
      {
        name: 'Dashboard',
        icon: 'chart-pie',
        to: '/dashboard',
        exact: true
      },
      {
        name: 'Region',
        icon: faMapMarkerAlt,
        active: true,
        children: [
          {
            name: 'List',
            to: '/region-list',
            exact: true,
            active: true
          },
          {
            name: 'Add',
            to: '/region/add-region',
            exact: true,
            active: true
          }
        ]
      },
      {
        name: 'ride category',
        icon: faCar,
        active: true,
        children: [
          {
            name: 'List',
            to: '/service-list',
            exact: true
          },
          {
            name: 'Add',
            to: '/service/add-service',
            exact: true
          }
        ]
      },
      {
        name: 'consumer details',
        icon: faRoad,
        active: true,
        children: [
          {
            name: 'List',
            to: '/rider-list',
            exact: true,
            active: true
          }
          // ,
          // {
          //   name: 'Add',
          //   to: '/rider/add-rider',
          //   exact: true,
          //   active: true
          // }
        ]
      },
      {
        name: 'fake users',
        icon: faRoad,
        active: true,
        children: [
          {
            name: 'List',
            to: '/fake-users',
            exact: true,
            active: true
          }
          // ,
          // {
          //   name: 'Add',
          //   to: '/rider/add-rider',
          //   exact: true,
          //   active: true
          // }
        ]
      },
      {
        name: 'drivers details',
        icon: faAddressCard,
        active: true,
        children: [
          {
            name: 'List',
            to: '/driver-list',
            exact: true,
            active: true
          }
        ]
      },
      {
        name: 'hotels details',
        icon: faAddressCard,
        active: true,
        children: [
          {
            name: 'List',
            to: '/property-owner-list',
            exact: true,
            active: true
          }
        ]
      },

      {
        name: 'ride bookings',
        icon: faCarSide,
        active: true,
        children: [
          {
            name: 'List',
            to: '/ride-request-list',
            exact: true,
            active: true
          }
        ]
      },
      {
        name: 'hotels bookings',
        icon: faLandmark,
        active: true,
        children: [
          {
            name: 'List',
            to: '/property-request-list',
            exact: true,
            active: true
          }
        ]
      },
      // {
      //   name: 'Account Setting',
      //   icon: faUserGear,
      //   active: true,
      //   children: [
      //     {
      //       name: 'Role List',
      //       to: '/account-setting/role-list',
      //       exact: true,
      //       active: true
      //     },
      //     {
      //       name: 'Permission List',
      //       to: '/account-setting/permission-list',
      //       exact: true,
      //       active: true
      //     }
      //   ]
      // },
      // {
      //   name: 'Pages',
      //   icon: faFile,
      //   active: true,
      //   children: [
      //     {
      //       name: 'Terms And Condition',
      //       to: '/terms-condition',
      //       exact: true,
      //       active: true
      //     },
      //     {
      //       name: 'Privacy Policy',
      //       to: '/privacy-policy',
      //       exact: true,
      //       active: true
      //     },
      //     {
      //       name: 'Contact Us',
      //       to: '/contact-us',
      //       exact: true,
      //       active: true
      //     }
      //   ]
      // },
      // {
      //   name: 'Settings',
      //   icon: faGear,
      //   to: '/dashboard',
      //   active: true,
      //   exact: true
      // },
      // {
      //   name: 'Chat',
      //   icon: faCommentDots,
      //   to: '/chat',
      //   active: true,
      //   exact: true
      // },
      {
        name: 'hotel category',
        icon: faSliders,
        active: true,
        children: [
          {
            name: 'List',
            to: '/system-property-category-list',
            exact: true,
            active: true
          },
          {
            name: 'Add',
            to: '/add-system-property-category',
            exact: true,
            active: true
          }
        ]
      },
      {
        name: 'hotel services ',
        icon: faLayerGroup,
        active: true,
        children: [
          {
            name: 'List',
            to: '/system-service-category-list',
            exact: true,
            active: true
          },
          {
            name: 'Add',
            to: '/add-system-service-category',
            exact: true,
            active: true
          }
        ]
      },
      {
        name: 'Map Locations',
        icon: faMapMarkerAlt,
        to: '/map-location',
        active: true,
        exact: true
      },
      {
        name: 'Notifications',
        icon: faEnvelope,
        active: true,
        children: [
          {
            name: 'List',
            to: '/list-notification',
            exact: true,
            active: true
          },
          {
            name: 'Add',
            to: '/add-notification',
            exact: true,
            active: true
          }
        ]
      }
    ]
  }
];

export const publicRoutes = [
  {
    path: '/auth/login',
    element: <Login />
  }
];

export const privateRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/region-list',
    element: <RegionList />
  },
  {
    path: '/region/:id/edit',
    element: <RegionEdit />
  },
  {
    path: '/region/add-region',
    element: <AddRegion />
  },
  {
    path: '/service-list',
    element: <ServiceList />
  },
  {
    path: '/service/:id/edit',
    element: <EditService />
  },
  {
    path: '/service/add-service',
    element: <AddService />
  },
  {
    path: '/rider-list',
    element: <RiderList />
  },
  {
    path: '/fake-users',
    element: <FakeUser />
  },
  // {
  //   path: '/rider/add-rider',
  //   element: <AddRider />
  // },
  {
    path: '/driver-list',
    element: <DriverList />
  },
  {
    path: '/property-owner-list',
    element: <PropertyOwnerList />
  },
  {
    path: '/driver-details/:id',
    element: <Profile />
  },
  {
    path: '/property-owner-details/:id',
    element: <PropertyOwnerProfile />
  },
  {
    path: '/ride-request-list',
    element: <RideRequestList />
  },
  {
    path: '/ride-request-details/:id',
    element: <RideRequestProfile />
  },
  {
    path: '/property-request-list',
    element: <PropertyRequestList />
  },
  {
    path: '/property-request-details/:id',
    element: <PropertyRequestProfile />
  },
  {
    path: '/account-setting/role-list',
    element: <RoleList />
  },
  {
    path: '/account-setting/permission-list',
    element: <PermissionList />
  },
  {
    path: '/chat',
    element: <Chat />
  },
  {
    path: '/system-property-category-list',
    element: <PropertyCategory />
  },
  {
    path: '/add-system-property-category',
    element: <AddPropertyCategory />
  },
  {
    path: '/system-property-category/:id/edit',
    element: <EditPropertyCategory />
  },
  {
    path: '/system-service-category-list',
    element: <PropertyServiceList />
  },
  {
    path: '/add-system-service-category',
    element: <AddServiceCategory />
  },
  {
    path: '/system-service-category/:id/edit',
    element: <EditServiceCategory />
  },
  {
    path: '/map-location',
    element: <MapLocation />
  },
  {
    path: '/list-notification',
    element: <ListNotification />
  },
  {
    path: '/add-notification',
    element: <AddNotification />
  },
  {
    path: '/property-owner/:id/ride-bookings',
    element: <Bookings />
  },
  {
    path: '/property-owner/:id/hotel-bookings',
    element: <Bookings />
  },
  {
    path: '/driver/:id/ride-bookings',
    element: <Bookings />
  },
  {
    path: '/driver/:id/hotel-bookings',
    element: <Bookings />
  },
  {
    path: '/user/:id/ride-bookings',
    element: <Bookings />
  },
  {
    path: '/user/:id/hotel-bookings',
    element: <Bookings />
  },
  {
    path: '/fake-user/:id/ride-bookings',
    element: <FakeUserBookings />
  },
  {
    path: '/fake-user/:id/hotel-bookings',
    element: <FakeUserBookings />
  },
  {
    path: '/hotel-bookings/:id/location',
    element: <PropertyRequestLocation />
  },
  {
    path: '/ride-bookings/:id/location',
    element: <RideRequestLocation />
  }
];
