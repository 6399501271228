/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, FormControl, InputGroup, Row, Col } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';
import { DateRange } from 'react-date-range';
import { useNavigate } from 'react-router-dom';

const AdvanceTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  placeholder = 'Search...',
  className
}) => {
  const rangeParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [value, setValue] = useState(globalFilter);
  const [showPicker, setShowPicker] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: rangeParams.get('start_date') || new Date(),
      endDate: rangeParams.get('end_date') || new Date(),
      key: 'selection'
    }
  ]);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleSelect = date => {
    setRange([date.selection]);
  };

  const handleFitler = () => {
    const startDate = range[0].startDate.toString();
    const endDate = range[0].endDate.toString();
    navigate(
      `${location.pathname}?start_date=${new Date(
        startDate
      ).toISOString()}&end_date=${new Date(endDate).toISOString()}`
    );
    setShowPicker(false);
  };

  return (
    <Row>
      <Col lg={5}>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '10px'
            }}
          >
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
              onClick={() => setShowPicker(!showPicker)}
            >
              <FontAwesomeIcon icon="calendar-alt" />
            </Button>
            <Button
              variant="outline-secondary"
              className="border-300 mx-1 btn-sm hover-border-secondary"
              onClick={() => navigate(location.pathname)}
            >
              Cancel
            </Button>
            {range[0].startDate < range[0].endDate && (
              <Button
                variant="outline-success"
                className="border-300 btn-sm hover-border-success"
                onClick={handleFitler}
              >
                Apply
              </Button>
            )}
          </div>
          {showPicker && (
            <div
              style={{
                position: 'absolute',
                zIndex: 9999,
                right: 0,
                boxShadow: '2px 2px 20px 2px',
                width: window.screen.width < 500 ? '100%' : '100',
                overflowX: 'auto'
              }}
            >
              <DateRange
                showSelectionPreview={false}
                moveRangeOnFirstSelection={true}
                scroll={{ enabled: true }}
                months={1}
                direction="vertical"
                ranges={range}
                onChange={date => handleSelect(date)}
              />
            </div>
          )}
        </div>
      </Col>
      <Col lg={7}>
        <InputGroup className={classNames(className, 'position-relative')}>
          <FormControl
            value={value || ''}
            onChange={({ target: { value } }) => {
              setValue(value);
              onChange(value);
            }}
            size="sm"
            id="search"
            placeholder={placeholder}
            type="search"
            className="shadow-none"
          />
          <Button
            size="sm"
            variant="outline-secondary"
            className="border-300 hover-border-secondary"
          >
            <FontAwesomeIcon icon="search" className="fs--1" />
          </Button>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default AdvanceTableSearchBox;
