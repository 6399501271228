import React from 'react';
import Routing from 'routes/routes';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const App = () => {
  return <Routing />;
};

export default App;
