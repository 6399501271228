import axios from 'utils/axios';
import {
  PROPERTY_REQUEST_LIST,
  UPDATE_PROPERTY_REQUEST
} from 'utils/axios/endpoints';

export const handleGetPropertyRequestList = async (
  params = '',
  pagination = '?page=1&limit=10'
) => {
  const res = await axios.get(PROPERTY_REQUEST_LIST + pagination + params);
  return res;
};

export const handleUpdatePropertyRequestList = async (id, body) => {
  const res = await axios.post(UPDATE_PROPERTY_REQUEST + id, body);
  return res;
};
