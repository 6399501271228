import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useRangeParams = () => {
  const [filterParams, setFilterParams] = useState('');
  const location = useLocation();

  useEffect(() => {
    let rangeParams = new URLSearchParams(location.search);

    if (rangeParams.has('start_date') && rangeParams.has('end_date')) {
      setFilterParams(
        `?start_date=${rangeParams.get(
          'start_date'
        )}&end_date=${rangeParams.get('end_date')}`
      );
    }

    return () => setFilterParams('');
  }, [location.search]);

  return { params: filterParams };
};

export default useRangeParams;
