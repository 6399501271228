export const timezones = [
  { value: 'GMT-11:00', label: 'GMT-11:00 Pacific/Midway' },
  { value: 'GMT-11:00', label: 'GMT-11:00 Pacific/Pago_Pago' },
  { value: 'GMT-10:00', label: 'GMT-10:00 Pacific/Honolulu' },
  { value: 'GMT-09:00', label: 'GMT-09:00 America/Adak' },
  { value: 'GMT-08:00', label: 'GMT-08:00 America/Anchorage' },
  { value: 'GMT-08:00', label: 'GMT-08:00 America/Juneau' },
  { value: 'GMT-08:00', label: 'GMT-08:00 America/Metlakatla' },
  { value: 'GMT-08:00', label: 'GMT-08:00 America/Nome' },
  { value: 'GMT-08:00', label: 'GMT-08:00 America/Sitka' },
  { value: 'GMT-08:00', label: 'GMT-08:00 America/Yakutat' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Creston' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Dawson_Creek' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Fort_Nelson' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Hermosillo' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Mazatlan' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Tijuana' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Dawson' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Los_Angeles' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Phoenix' },
  { value: 'GMT-07:00', label: 'GMT-07:00 America/Whitehorse' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Regina' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Swift_Current' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Bahia_Banderas' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Belize' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Boise' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Cambridge_Bay' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Chihuahua' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Costa_Rica' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Denver' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Edmonton' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/El_Salvador' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Guatemala' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Inuvik' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Managua' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Merida' },// Spain
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Mexico_City' },// Mexico
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Monterrey' },
  { value: 'GMT-06:00', label: 'GMT-06:00 America/Tegucigalpa' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Bogota' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Cayman' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Guayaquil' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Panama' },
  { value: 'GMT-05:00', label: 'GMT-05:00 Pacific/Easter' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Atikokan' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Cancun' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Chicago' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Indiana/Knox' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Indiana/Tell_City' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Jamaica' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Matamoros' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Menominee' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/North_Dakota/Beulah' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/North_Dakota/Center' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/North_Dakota/New_Salem' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Ojinaga' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Rankin_Inlet' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Resolute' },
  { value: 'GMT-05:00', label: 'GMT-05:00 America/Winnipeg' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Anguilla' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Antigua' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Aruba' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Asuncion' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Caracas' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Curacao' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Detroit' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Dominica' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Grand_Turk' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Grenada' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Guadeloupe' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Kralendijk' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Lower_Princes' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Marigot' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Montserrat' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Port_of_Spain' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Santo_Domingo' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/St_Barthelemy' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/St_Kitts' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/St_Lucia' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/St_Thomas' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/St_Vincent' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Tortola' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Barbados' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Blanc-Sablon' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Havana' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Indiana/Indianapolis' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Indiana/Marengo' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Indiana/Petersburg' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Indiana/Vevay' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Indiana/Vincennes' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Indiana/Winamac' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Iqaluit' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Kentucky/Louisville' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Kentucky/Monticello' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/La_Paz' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Martinique' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Nassau' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/New_York' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Port-au-Prince' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Puerto_Rico' },
  { value: 'GMT-04:00', label: 'GMT-04:00 America/Toronto' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/Buenos_Aires' },// Argentina
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/Catamarca' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/Cordoba' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/Jujuy' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/La_Rioja' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/Mendoza' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/Rio_Gallegos' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/Salta' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/San_Juan' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/San_Luis' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/Tucuman' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Argentina/Ushuaia' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Montevideo' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Paramaribo' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Punta_Arenas' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Santiago' },
  { value: 'GMT-03:00', label: 'GMT-03:00 Atlantic/Stanley' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Glace_Bay' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Goose_Bay' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Halifax' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Moncton' },
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Thule' },
  { value: 'GMT-03:00', label: 'GMT-03:00 Atlantic/Bermuda' },
  { value: 'GMT-02:30', label: 'GMT-02:30 America/St_Johns' },
  { value: 'GMT-02:00', label: 'GMT-02:00 America/Miquelon' },
  { value: 'GMT-01:00', label: 'GMT-01:00 Atlantic/Azores' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Abidjan' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Accra' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Bamako' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Banjul' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Bissau' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Conakry' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Dakar' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Freetown' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Lome' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Monrovia' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Nouakchott' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Ouagadougou' },
  { value: 'GMT', label: 'GMT 00:00 Africa/Sao_Tome' },
  { value: 'GMT', label: 'GMT 00:00 America/Danmarkshavn' },
  { value: 'GMT', label: 'GMT 00:00 Atlantic/Madeira' },
  { value: 'GMT', label: 'GMT 00:00 Atlantic/Reykjavik' },
  { value: 'GMT', label: 'GMT 00:00 Atlantic/St_Helena' },
  { value: 'GMT', label: 'GMT 00:00 UTC' },
  { value: 'GMT', label: 'GMT 00:00 Atlantic/Canary' },
  { value: 'GMT', label: 'GMT 00:00 Atlantic/Faroe' },
  { value: 'GMT', label: 'GMT 00:00 Europe/Dublin' },
  { value: 'GMT', label: 'GMT 00:00 Europe/Guernsey' },
  { value: 'GMT', label: 'GMT 00:00 Europe/Isle_of_Man' },
  { value: 'GMT', label: 'GMT 00:00 Europe/Jersey' },
  { value: 'GMT', label: 'GMT 00:00 Europe/Lisbon' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Bangui' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Brazzaville' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Douala' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Kinshasa' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Lagos' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Libreville' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Luanda' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Malabo' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Niamey' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Porto-Novo' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Algiers' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Ceuta' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Ndjamena' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Africa/Tunis' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Arctic/Longyearbyen' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Andorra' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Belgrade' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Berlin' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Bratislava' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Brussels' },// Belgium
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Budapest' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Busingen' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Copenhagen' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Gibraltar' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Ljubljana' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Luxembourg' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Madrid' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Malta' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Monaco' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Oslo' },// Norway
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Podgorica' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Prague' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/San_Marino' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Sarajevo' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Skopje' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Stockholm' }, // Sweden
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Tirane' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Vaduz' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Vatican' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Vienna' },
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Zagreb' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Blantyre' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Bujumbura' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Gaborone' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Harare' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Johannesburg' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Kigali' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Lubumbashi' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Lusaka' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Maputo' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Windhoek' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Athens' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Bucharest' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Chisinau' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Cairo' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Juba' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Khartoum' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Maseru' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Mbabane' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Africa/Tripoli' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Asia/Beirut' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Asia/Famagusta' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Asia/Gaza' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Asia/Hebron' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Asia/Jerusalem' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Asia/Nicosia' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Helsinki' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Kaliningrad' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Mariehamn' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Riga' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Sofia' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Tallinn' },
  { value: 'GMT+02:00', label: 'GMT+02:00 Europe/Vilnius' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Africa/Addis_Ababa' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Africa/Asmara' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Africa/Dar_es_Salaam' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Africa/Djibouti' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Africa/Kampala' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Africa/Mogadishu' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Africa/Nairobi' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Indian/Antananarivo' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Indian/Comoro' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Amman' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Damascus' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Europe/Minsk' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Europe/Simferopol' },
  { value: 'GMT+03:30', label: 'GMT+03:30 Asia/Tehran' },
  { value: 'GMT+05:00', label: 'GMT+05:00 Asia/Yekaterinburg' },
  { value: 'GMT+05:00', label: 'GMT+05:00 Indian/Maldives' },
  { value: 'GMT+05:00', label: 'GMT+05:00 Asia/Karachi' },
  { value: 'GMT+05:30', label: 'GMT+05:30 Asia/Colombo' },
  { value: 'GMT+05:30', label: 'GMT+05:30 Asia/Kolkata' },
  { value: 'GMT+06:30', label: 'GMT+06:30 Asia/Yangon' },
  { value: 'GMT+07:00', label: 'GMT+07:00 Asia/Bangkok' },
  { value: 'GMT+07:00', label: 'GMT+07:00 Asia/Ho_Chi_Minh' },
  { value: 'GMT+07:00', label: 'GMT+07:00 Asia/Phnom_Penh' },
  { value: 'GMT+07:00', label: 'GMT+07:00 Asia/Pontianak' },
  { value: 'GMT+07:00', label: 'GMT+07:00 Asia/Vientiane' },
  { value: 'GMT+08:00', label: 'GMT+08:00 Asia/Irkutsk' },
  { value: 'GMT+08:00', label: 'GMT+08:00 Asia/Kuala_Lumpur' },
  { value: 'GMT+08:00', label: 'GMT+08:00 Asia/Manila' },
  { value: 'GMT+08:00', label: 'GMT+08:00 Asia/Singapore' },
  { value: 'GMT+08:00', label: 'GMT+08:00 Asia/Hong_Kong' },
  { value: 'GMT+08:00', label: 'GMT+08:00 Asia/Macau' },
  { value: 'GMT+08:00', label: 'GMT+08:00 Asia/Shanghai' },
  { value: 'GMT+08:00', label: 'GMT+08:00 Australia/Perth' },
  { value: 'GMT+09:00', label: 'GMT+09:00 Asia/Pyongyang' },
  { value: 'GMT+09:00', label: 'GMT+09:00 Asia/Tokyo' },
  { value: 'GMT+09:30', label: 'GMT+09:30 Australia/Darwin' },
  { value: 'GMT+10:00', label: 'GMT+10:00 Pacific/Guam' },
  { value: 'GMT+10:00', label: 'GMT+10:00 Pacific/Saipan' },
  { value: 'GMT+10:00', label: 'GMT+10:00 Australia/Brisbane' },
  { value: 'GMT+10:00', label: 'GMT+10:00 Australia/Lindeman' },
  { value: 'GMT+10:30', label: 'GMT+10:30 Australia/Adelaide' },
  { value: 'GMT+10:30', label: 'GMT+10:30 Australia/Broken_Hill' },
  { value: 'GMT+11:00', label: 'GMT+11:00 Australia/Lord_Howe' },
  { value: 'GMT+11:00', label: 'GMT+11:00 Antarctica/Macquarie' },
  { value: 'GMT+11:00', label: 'GMT+11:00 Australia/Hobart' },
  { value: 'GMT+11:00', label: 'GMT+11:00 Australia/Melbourne' },
  { value: 'GMT+11:00', label: 'GMT+11:00 Australia/Sydney' },
  { value: 'GMT+13:00', label: 'GMT+13:00 Antarctica/McMurdo' },
  { value: 'GMT+13:00', label: 'GMT+13:00 Pacific/Auckland' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Qatar' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Abu_Dhabi' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Manama' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Kuwait' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Doha' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Bahrain' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Indian/Mayotte' },
  { value: 'GMT+04:00', label: 'GMT+04:00 Asia/Dubai' },
  { value: 'GMT+04:00', label: 'GMT+04:00 Asia/Muscat' },// Oman
  { value: 'GMT+04:00', label: 'GMT+04:00 Asia/Tbilisi' },
  { value: 'GMT+04:00', label: 'GMT+04:00 Asia/Yerevan' },
  { value: 'GMT+04:00', label: 'GMT+04:00 Indian/Mahe' },
  { value: 'GMT+04:00', label: 'GMT+04:00 Indian/Mauritius' },
  { value: 'GMT+04:00', label: 'GMT+04:00 Indian/Reunion' },
  // Additional Gulf Countries
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Baghdad' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Aden' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Almaty' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Baku' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Bishkek' },
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Dhaka' },
  { value: 'GMT+00:00', label: 'GMT+00:00 Europe/London' }, // UK
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Paris' }, // France
  { value: 'GMT-03:00', label: 'GMT-03:00 America/Sao_Paulo' }, // Brazil
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Rome' }, // Italy
  { value: 'GMT-08:00', label: 'GMT-08:00 America/Vancouver' }, // Canada
  { value: 'GMT+09:00', label: 'GMT+09:00 Asia/Seoul' }, // South Korea
  { value: 'GMT+03:00', label: 'GMT+03:00 Europe/Moscow' }, // Russia
  { value: 'GMT+07:00', label: 'GMT+07:00 Asia/Jakarta' }, // WIB - Western Indonesian Time
  { value: 'GMT+08:00', label: 'GMT+08:00 Asia/Makassar' }, // WITA - Central Indonesian Time
  { value: 'GMT+09:00', label: 'GMT+09:00 Asia/Jayapura' }, // WIT - Eastern Indonesian Time
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Amsterdam' }, // Netherlands
  { value: 'GMT+03:00', label: 'GMT+03:00 Asia/Riyadh' }, // Saudi Arabia
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Zurich' }, // Switzerland
  { value: 'GMT+03:00', label: 'GMT+03:00 Europe/Istanbul' }, // Turkey
  { value: 'GMT+08:00', label: 'GMT+08:00 Asia/Taipei' }, // Taiwan
  { value: 'GMT+01:00', label: 'GMT+01:00 Europe/Warsaw' }, // Poland
];
