import axios from 'utils/axios';
import {
  RIDE_REQUEST_LIST,
  PROPERTY_REQUEST_LIST
} from 'utils/axios/endpoints';

export const handleBookings = async (
  params = '',
  pagination = '&page=1&limit=10',
  id,
  isRide,
  type
) => {
  let res;
  if (isRide) {
    res = await axios.get(
      RIDE_REQUEST_LIST +
        `?id=${id}&type=${type}` +
        pagination.replace('?', '&') +
        params
    );
  } else {
    res = await axios.get(
      PROPERTY_REQUEST_LIST +
        `?id=${id}&type=${type}` +
        pagination.replace('?', '&') +
        params
    );
  }
  return res;
};
