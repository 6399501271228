import React from 'react';
import { Link } from 'react-router-dom';
import { usePropertyRequestList } from './helper';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvancePagination from 'components/common/advance-table/AdvancePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ActionButton from 'components/common/ActionButton';
import Loading from 'components/loading';
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useExportTable from 'hooks/useExportTable';
import { camelize } from '../../../helpers/utils';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';

const PropertyRequestList = () => {
  const {
    isLoading,
    propertyRequestList,
    updatePropertyRequestList,
    refundPropertyRequestList,
    metaList
  } = usePropertyRequestList();

  const handleChangeStatus = (id, status) => {
    if (status == 'cancelled') {
      updatePropertyRequestList(id, { status, cancel_by: 'admin' });
    } else {
      updatePropertyRequestList(id, { status });
    }
  };

  const columns = [
    {
      accessor: 'id',
      Header: '#'
    },
    {
      accessor: 'user_detail.first_name',
      Header: 'User Name',
      Cell: ({ row }) => (
        <span>
          {row.original.user_detail.first_name}
          {'·'}
          {row.original.user_detail.last_name}
        </span>
      )
    },
    {
      accessor: 'property_owner_detail.first_name',
      Header: 'Property Owner Name',
      Cell: ({ row }) => {
        if (row.original.property_owner_detail) {
          return (
            <span>
              {row.original.property_owner_detail.first_name}{' '}
              {row.original.property_owner_detail.last_name}
            </span>
          );
        } else {
          return <span>Not accepted by an property owner</span>;
        }
      }
    },
    {
      accessor: 'sub_total',
      Header: 'sub total'
    },
    {
      accessor: 'created_at',
      Header: 'Created At'
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: ({ row }) => <span>{row.original.status}</span>
    },
    {
      id: 'property_detail.name',
      Header: 'Property Details',
      Cell: ({ row }) => {
        if (row.original.property_detail) {
          return <span>{row.original.property_detail.name}</span>;
        } else {
          return <span>No property Details</span>;
        }
      }
    },
    {
      Header: 'Action',
      Cell: ({ row }) => (
        <div className="d-flex">
          {row.original.property_owner_detail && (
            <Link
              to={`/hotel-bookings/${row.original.id}/location`}
              className="p-0 me-2"
            >
              <ActionButton
                onClick={() => {
                  sessionStorage.setItem(
                    'hotelBooking' + row.original.id,
                    JSON.stringify(row.original)
                  );
                }}
                icon="map-marker-alt"
                title="location"
                variant="action"
                className="p-0 me-2"
              />
            </Link>
          )}
          <Link
            to={`/property-request-details/${row.original.id}`}
            className="p-0 me-2"
          >
            <ActionButton
              onClick={() => {
                sessionStorage.setItem(
                  'propertyRequestDetail' + row.original.id,
                  JSON.stringify(row.original)
                );
              }}
              icon="eye"
              title="View"
              variant="action"
              className="p-0 me-2"
            />
          </Link>
          {![
            'cancelled',
            'booking_request',
            'new_property_request',
            'completed'
          ].includes(row.original.status) && (
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-button"
                className="text-400 dropdown-caret-none me-2"
                variant="action"
                size="sm"
              >
                <FontAwesomeIcon icon="pencil" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-2 border">
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(
                      row.original.id,
                      'new_property_requested'
                    )
                  }
                  disabled={row.original.status == 'new_property_requested'}
                  className="cursor-pointer"
                >
                  New Property Requested
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'booking_request')
                  }
                  disabled={row.original.status == 'booking_request'}
                  className="cursor-pointer"
                >
                  Booking Request
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'booking_accepted')
                  }
                  disabled={row.original.status == 'booking_accepted'}
                  className="cursor-pointer"
                >
                  Booking Accepted
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'accepted')
                  }
                  disabled={row.original.status == 'accepted'}
                  className="cursor-pointer"
                >
                  Accepted
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'check_in')
                  }
                  disabled={row.original.status == 'check_in'}
                  className="cursor-pointer"
                >
                  Check In
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'check_out')
                  }
                  disabled={row.original.status == 'check_out'}
                  className="cursor-pointer"
                >
                  Check Out
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'cancelled')
                  }
                  disabled={row.original.status == 'cancelled'}
                  className="cursor-pointer text-danger"
                >
                  Cancelled
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'completed')
                  }
                  disabled={row.original.status == 'completed'}
                  className="cursor-pointer"
                >
                  Completed
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {row.original.status == 'cancelled' && (
            <Link
              onClick={() =>
                refundPropertyRequestList({
                  request_id: row.original.id,
                  request_type: 'property'
                })
              }
              className="p-0 me-2"
            >
              <ActionButton
                icon="wallet"
                title="Refund"
                variant="action"
                className="p-0 me-2"
              />
            </Link>
          )}
        </div>
      )
    }
  ];

  function AdvanceTableExample() {
    return (
      <AdvanceTableWrapper
        columns={columns}
        data={propertyRequestList}
        sortable
        pagination
        perPage={metaList.perPage}
        metaList={metaList}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvancePagination
            rowCount={propertyRequestList.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
            metaList={metaList}
          />
        </div>
      </AdvanceTableWrapper>
    );
  }

  const selectedColumns = [
    'id',
    'user_name',
    'property_owner_name',
    'property_detail',
    'sub_total',
    'status'
  ];
  const exportToExcel = useExportTable(
    propertyRequestList,
    'propertyRequestList.xlsx',
    selectedColumns
  );

  return (
    <Card>
      <Card.Header>
        <Flex justifyContent={'between'} alignItems={'center'}>
          <h5
            className="mb-0 hover-actions-trigger"
            id={camelize('Property Booking List')}
          >
            {'Property Booking List'}
            <HashLink
              to={`${location.pathname}#${camelize('Property Booking List')}`}
              className="hover-actions ps-2"
              style={{ top: 0, right: '-25px' }}
            >
              #
            </HashLink>
          </h5>
          {!isLoading && exportToExcel}
        </Flex>
      </Card.Header>
      <Card.Body style={{ height: '500px', overflow: 'scroll' }}>
        {isLoading ? (
          <Loading className={'loadingTable'} />
        ) : (
          <AdvanceTableExample />
        )}
      </Card.Body>
    </Card>
  );
};

PropertyRequestList.propTypes = {
  // Define prop type validations for the props mentioned in the error messages
  row: PropTypes.shape({
    original: PropTypes.shape({
      user_detail: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string
      }),
      property_owner_detail: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string
      }),
      status: PropTypes.string,
      property_detail: PropTypes.shape({
        name: PropTypes.string
      }),
      id: PropTypes.number,
      sub_total: PropTypes.number,
      created_at: PropTypes.string
    })
  })
};

export default PropertyRequestList;
