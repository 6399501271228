import React from 'react';
import { Link } from 'react-router-dom';
import { useRideRequestList } from './helper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvancePagination from 'components/common/advance-table/AdvancePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ActionButton from 'components/common/ActionButton';
import Loading from 'components/loading';
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useExportTable from 'hooks/useExportTable';
import Flex from 'components/common/Flex';
import { camelize } from '../../../helpers/utils';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';

const RideRequestList = () => {
  const {
    isLoading,
    rideRequestList,
    updateRideRequestList,
    refundRideRequestList,
    metaList
  } = useRideRequestList();

  const handleChangeStatus = (id, status) => {
    if (status == 'cancelled') {
      updateRideRequestList(id, { status, cancel_by: 'admin' });
    } else {
      updateRideRequestList(id, { status });
    }
  };

  const columns = [
    {
      accessor: 'id',
      Header: '#'
    },
    {
      accessor: 'rider_detail?.first_name',
      Header: 'Rider Name',
      Cell: ({ row }) => (
        // Render Rider Name with both first and last names
        <span>
          {row.original.rider_detail?.first_name}{' '}
          {row.original.rider_detail?.last_name}
        </span>
      )
    },
    {
      accessor: 'driver_details?.first_name',
      Header: 'Driver Name',
      Cell: ({ row }) => {
        // Check if driver_details is not null
        if (row.original.driver_details) {
          // Render Rider Name with both first and last names
          return (
            <span>
              {row.original.driver_details?.first_name}{' '}
              {row.original.driver_details?.last_name}
            </span>
          );
        } else {
          // Handle the case where driver_details is null
          return <span>No Driver Assign</span>;
        }
      }
    },
    {
      accessor: 'sub_total',
      Header: 'sub total'
    },
    {
      accessor: 'created_at',
      Header: 'Created At'
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: ({ row }) => (
        // Render Rider Name with both first and last names
        <span>{row.original.status}</span>
      )
    },
    {
      id: 'service_details.name',
      Header: 'Service Details',
      Cell: ({ row }) => {
        // Check if service_details is not null
        if (row.original.service_details) {
          // Render the service name
          return <span>{row.original.service_details.name}</span>;
        } else {
          // Handle the case where service_details is null
          return <span>No Service Details</span>;
        }
      }
    },
    {
      Header: 'Action',
      Cell: ({ row }) => (
        <div className="d-flex">
          {row.original.driver_details && (
            <Link
              to={`/ride-bookings/${row.original.id}/location`}
              className="p-0 me-2"
            >
              <ActionButton
                onClick={() => {
                  sessionStorage.setItem(
                    'rideBooking' + row.original.id,
                    JSON.stringify(row.original)
                  );
                }}
                icon="map-marker-alt"
                title="location"
                variant="action"
                className="p-0 me-2"
              />
            </Link>
          )}
          <Link
            to={`/ride-request-details/${row.original.id}`}
            className="p-0 me-2"
          >
            <ActionButton
              onClick={() => {
                sessionStorage.setItem(
                  'rideRequestDetail' + row.original.id,
                  JSON.stringify(row.original)
                );
              }}
              icon="eye"
              title="View"
              variant="action"
              className="p-0 me-2"
            />
          </Link>
          {!['cancelled', 'new_ride_requested', 'completed'].includes(
            row.original.status
          ) && (
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-button"
                className="text-400 dropdown-caret-none me-2"
                variant="action"
                size="sm"
              >
                <FontAwesomeIcon icon="pencil" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2 border">
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'new_ride_requested')
                  }
                  disabled={row.original.status == 'new_ride_requested'}
                  className="cursor-pointer"
                >
                  New Ride Requested
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'accepted')
                  }
                  disabled={row.original.status == 'accepted'}
                  className="cursor-pointer"
                >
                  Accepted
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'arriving')
                  }
                  disabled={row.original.status == 'arriving'}
                  className="cursor-pointer"
                >
                  Arriving
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleChangeStatus(row.original.id, 'arrived')}
                  disabled={row.original.status == 'arrived'}
                  className="cursor-pointer"
                >
                  Arrived
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'in_progress')
                  }
                  disabled={row.original.status == 'in_progress'}
                  className="cursor-pointer"
                >
                  In Progress
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'cancelled')
                  }
                  disabled={row.original.status == 'cancelled'}
                  className="cursor-pointer text-danger"
                >
                  Cancelled
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(row.original.id, 'completed')
                  }
                  disabled={row.original.status == 'completed'}
                  className="cursor-pointer"
                >
                  Completed
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {row.original.status == 'cancelled' && (
            <Link
              onClick={() =>
                refundRideRequestList({
                  request_id: row.original.id,
                  request_type: 'ride'
                })
              }
              className="p-0 me-2"
            >
              <ActionButton
                icon="wallet"
                title="Refund"
                variant="action"
                className="p-0 me-2"
              />
            </Link>
          )}
        </div>
      )
    }
  ];

  function AdvanceTableExample() {
    return (
      <AdvanceTableWrapper
        columns={columns}
        data={rideRequestList}
        sortable
        pagination
        perPage={metaList.perPage}
        metaList={metaList}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvancePagination
            rowCount={rideRequestList.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
            metaList={metaList}
          />
        </div>
      </AdvanceTableWrapper>
    );
  }

  const exportColumns = [
    'id',
    'driver_details.first_name',
    'rider_detail.first_name',
    'sub_total',
    'status'
  ];
  const exportToExcel = useExportTable(
    rideRequestList,
    'rideRequestList.xlsx',
    exportColumns
  );

  return (
    <Card>
      <Card.Header>
        <Flex justifyContent={'between'} alignItems={'center'}>
          <h5
            className="mb-0 hover-actions-trigger"
            id={camelize('Ride Booking List')}
          >
            {'Ride Booking List'}
            <HashLink
              to={`${location.pathname}#${camelize('Ride Booking List')}`}
              className="hover-actions ps-2"
              style={{ top: 0, right: '-25px' }}
            >
              #
            </HashLink>
          </h5>
          {!isLoading && exportToExcel}
        </Flex>
      </Card.Header>
      <Card.Body style={{ height: '500px', overflow: 'scroll' }}>
        {isLoading ? (
          <Loading className={'loadingTable'} />
        ) : (
          <AdvanceTableExample />
        )}
      </Card.Body>
    </Card>
  );
};

RideRequestList.propTypes = {
  // Define prop type validations for the props mentioned in the error messages
  row: PropTypes.shape({
    original: PropTypes.shape({
      rider_detail: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string
      }),
      driver_details: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string
      }),
      status: PropTypes.string,
      service_details: PropTypes.shape({
        name: PropTypes.string
      }),
      id: PropTypes.number
    })
  })
};

export default RideRequestList;
