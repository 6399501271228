import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';

const ChatContentHeader = ({ thread }) => {
  return (
    <div className="chat-content-header" style={{ padding: '1.1rem' }}>
      <Row className="flex-between-center">
        <Col xs={12} md={12} as={Flex} alignItems="center">
          <Avatar
            size="xl"
            src={`${process.env.REACT_APP_IMAGE_BASE_URL}${thread.profileImage}`}
          />
          <div className="min-w-0 px-2">
            <h5 className="mb-0 text-truncate fs-0">{thread.name}</h5>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ChatContentHeader.propTypes = {
  thread: PropTypes.object.isRequired,
  setHideSidebar: PropTypes.func.isRequired
};

export default ChatContentHeader;
