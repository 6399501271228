import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';

import Message from './Message';
import { useAppSelector } from 'store/hook';
import moment from 'moment';
import { useEffect } from 'react';
import { store } from 'store';
import { setScrollToBottom } from 'store';
import Loading from 'components/loading';

const ChatContentBody = () => {
  let lastDate = null;
  const messagesEndRef = useRef();
  const { messages, scrollToBottom, isChatLoading, receiverId } =
    useAppSelector(state => state.app);

  useEffect(() => {
    if (scrollToBottom) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
      store.dispatch(setScrollToBottom(false));
    }
  }, [scrollToBottom]);

  return (
    <div className="chat-content-body" style={{ display: 'inherit' }}>
      <SimpleBarReact style={{ height: '100%' }}>
        {receiverId !== '' && (
          <div className="chat-content-scroll-area">
            {!isChatLoading ? (
              <>
                {messages.length > 1 ? (
                  messages?.map(
                    (
                      { message, from_user, created_at, m_type, attachement },
                      index
                    ) => (
                      <div key={index}>
                        {lastDate !==
                          moment(created_at).add(1, 'day').format('MMMM D') && (
                          <div className="text-center fs--2 text-500">{`${moment(
                            created_at
                          )
                            .add(1, 'day')
                            .format('MMMM D')}, ${moment(created_at).format(
                            'h:mm A'
                          )}`}</div>
                        )}
                        {(() => {
                          lastDate = moment(created_at)
                            .add(1, 'day')
                            .format('MMMM D');
                        })()}
                        <Message
                          message={message}
                          m_type={m_type}
                          attachement={attachement}
                          senderUserId={from_user.id}
                          img={from_user.profile_image}
                          time={moment(created_at).format('h:mm A')}
                        />
                      </div>
                    )
                  )
                ) : (
                  <h2 className={'mb-1 text-700 fw-normal lh-1'}>
                    There is no message. start chat :)
                  </h2>
                )}
              </>
            ) : (
              <Loading className="loadingTable" />
            )}
          </div>
        )}

        <div ref={messagesEndRef} />
      </SimpleBarReact>
    </div>
  );
};

ChatContentBody.propTypes = {
  isLoading: PropTypes.bool
};

export default ChatContentBody;
