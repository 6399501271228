import { handleAddPropertyServices } from 'api-service/system-property-service';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export const useAddPropertyService = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formArr = [
    {
      title: 'Name',
      name: 'name',
      type: 'text',
      required: true
    },
    {
      title: 'hotel service icon',
      name: 'system_property_service_icon',
      type: 'file',
      required: true
    }
  ];
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = async data => {
    setIsLoading(true);

    const formData = new FormData();

    // Convert data to FormData
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    // Delete a specific item from FormData
    formData.delete('system_property_service_icon');

    if (data.system_property_service_icon) {
      for (let i = 0; i < data.system_property_service_icon.length; i++) {
        formData.append(
          'system_property_service_icon',
          data.system_property_service_icon[i]
        );
      }
    }

    const res = await handleAddPropertyServices(formData);
    if (res?.status === 200) {
      setIsLoading(false);
      reset();
    }
  };

  return {
    formArr,
    isLoading,
    control,
    errors,
    onSubmit,
    register,
    handleSubmit
  };
};
