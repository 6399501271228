import {
  handleGetRideRequestList,
  handleUpdateRideRequestList
} from 'api-service/ride-request';
import { handleRefundRequestList } from 'api-service/refund';
import { useEffect, useState } from 'react';
import useRangeParams from 'hooks/useRangeParams';
import useFetchPage from 'hooks/useFetchPage';

export const useRideRequestList = () => {
  const [rideRequestList, setRideRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { params } = useRangeParams();
  const [metaList, setMetaList] = useState([]);
  const { pagination } = useFetchPage();

  const getRideRequestList = async () => {
    setIsLoading(true);
    const res = await handleGetRideRequestList(params, pagination);
    if (res?.status === 200) {
      const rideRequestList = res?.data?.result?.data;
      setMetaList(res?.data?.result?.meta);
      setRideRequestList(rideRequestList.slice(0, 50));
      setIsLoading(false);
    }
  };

  const updateRideRequestList = async (id, status) => {
    await handleUpdateRideRequestList(id, status);
    const updatedStatus = status;
    const newArr = [];
    rideRequestList.forEach(el => {
      if (el.id == id) {
        el.status = updatedStatus.status;
        newArr.push(el);
      } else {
        newArr.push(el);
      }
    });
    setRideRequestList(newArr);
  };

  const refundRideRequestList = async body => {
    await handleRefundRequestList(body);
  };

  useEffect(() => {
    getRideRequestList();
  }, [params, pagination]);

  return {
    rideRequestList,
    isLoading,
    updateRideRequestList,
    refundRideRequestList,
    metaList
  };
};
