import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { useNotification } from './helper';
import Picker from 'emoji-picker-react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddNotification = () => {
  const [showPicker, setShowPicker] = useState(false);
  const {
    isLoading,
    formArr,
    errors,
    onSubmit,
    register,
    handleSubmit,
    setFormArr,
    userList,
    recipientType,
    setRecipientType,
    roleList,
    setSelectedUser
  } = useNotification();

  const onEmojiClick = event => {
    let newFormArr = [];
    formArr.forEach(el => {
      if (el.type == 'textarea') {
        el.value += event.emoji;
        newFormArr.push(el);
      } else {
        newFormArr.push(el);
      }
    });
    setFormArr(newFormArr);
  };

  const handleChange = (e, formArr) => {
    let newFormArr = [];
    formArr.forEach(el => {
      if (el.name == e.target.name) {
        el.value = e.target.value;
        newFormArr.push(el);
      } else {
        newFormArr.push(el);
      }
    });
    setFormArr(newFormArr);
  };

  const selectOptions = () => {
    if (recipientType == 'specific') {
      return userList.map(el => {
        return {
          value: el.id,
          label: `${el.first_name} ${el.last_name} (+92${el.phone_number})`
        };
      });
    } else if (recipientType == 'roleBase') {
      return roleList.map(el => {
        return { value: el.id, label: el.title };
      });
    }
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header title="Add Notification" light={false} />
      <FalconComponentCard.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            {formArr.map(({ name, title, value, type, required }) => {
              return (
                <>
                  <Col key={name} md="4">
                    {type == 'text' && (
                      <Form.Group
                        className="mb-3"
                        onChange={e => handleChange(e, formArr)}
                        controlId="formHookname"
                      >
                        <Form.Label>
                          {title} {required && '*'}
                        </Form.Label>
                        <Form.Control
                          type={type}
                          name={name}
                          value={value}
                          placeholder={`Enter your ${title}`}
                          isInvalid={!!errors?.[name]}
                          {...register(name, {
                            required: required
                              ? `${title} is required field.`
                              : false
                          })}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.[name] && errors?.[name]?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    {type == 'textarea' && (
                      <Form.Group
                        className="mb-3"
                        onChange={e => handleChange(e, formArr)}
                        controlId="formHookname"
                      >
                        <Form.Label>
                          {title} {required && '*'}
                        </Form.Label>
                        <div style={{ display: 'flex' }}>
                          <Form.Control
                            type={type}
                            name={name}
                            as="textarea"
                            value={value}
                            placeholder={`Enter your ${title}`}
                            isInvalid={!!errors?.[name]}
                            {...register(name, {
                              required: required
                                ? `${title} is required field.`
                                : false
                            })}
                          />
                          <div
                            style={{
                              width: '20px',
                              margin: '-4px 0 0 4px',
                              position: 'relative'
                            }}
                          >
                            <FontAwesomeIcon
                              icon={showPicker ? 'xmark' : 'face-smile'}
                              onClick={() => setShowPicker(!showPicker)}
                            />
                            {showPicker && (
                              <div
                                style={{
                                  position: 'absolute',
                                  zIndex: 1,
                                  boxShadow: '2px 2px 20px 2px'
                                }}
                              >
                                <Picker
                                  pickerStyle={{
                                    right: '20px',
                                    position: 'absolute',
                                    zIndex: 10,
                                    top: 0
                                  }}
                                  onEmojiClick={onEmojiClick}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors?.[name] && errors?.[name]?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Col>
                </>
              );
            })}
            <Col md="4">
              <Form.Group
                onChange={e => setRecipientType(e.target.id)}
                className="mb-3"
                controlId="formHooknamsdsdsde"
                value={recipientType}
              >
                <Form.Check
                  inline
                  label="Role Base"
                  name="recipientType"
                  type={'radio'}
                  id={'roleBase'}
                  defaultChecked={recipientType === 'roleBase'}
                />
                <Form.Check
                  inline
                  label="Selective User"
                  name="recipientType"
                  type={'radio'}
                  id={'specific'}
                  defaultChecked={recipientType === 'specific'}
                />
                <Form.Check
                  inline
                  label="General"
                  name="recipientType"
                  type={'radio'}
                  id={'general'}
                  defaultChecked={recipientType === 'general'}
                />
              </Form.Group>
              {recipientType !== 'general' && (
                <Form.Group
                  onChange={e => handleChange(e, formArr)}
                  className="mb-3"
                  controlId="formHooknamsdsdsde"
                >
                  <Select
                    options={selectOptions()}
                    isSearchable={true}
                    placeholder="Search or select..."
                    onChange={setSelectedUser}
                  />
                </Form.Group>
              )}
            </Col>
          </Row>

          <Button variant="primary" type="submit" disabled={isLoading}>
            Submit
          </Button>
        </Form>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default AddNotification;
