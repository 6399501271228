import axios from 'utils/axios';
import {
  PROPERTY_DETAILS,
  PROPERTY_OWNER_LIST,
  UPDATE_PROPERTY_DETAILS
} from 'utils/axios/endpoints';

export const handleGetPropertyOwnerList = async (params = '') => {
  const res = await axios.get(PROPERTY_OWNER_LIST + params);
  return res;
};

export const GetUserInfo = async ({ id }) => {
  const res = await axios.get(PROPERTY_DETAILS + id);
  return res;
};

export const UpdatePropertyStatus = async data => {
  const res = await axios.post(UPDATE_PROPERTY_DETAILS, data);
  return res;
};
