import { handleLogin } from 'api-service/auth';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { store, setToken, setUserInfo } from 'store';

export const useLogin = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async data => {
    setIsLoading(true);
    data.dial_code = '+92';
    data.remember = false;
    const res = await handleLogin(data);

    if (res.status === 200) {
      setIsLoading(false);
      store.dispatch(
        setToken({
          token: res?.data?.result?.token
        })
      );
      store.dispatch(
        setUserInfo({
          userInfo: res?.data?.result?.user
        })
      );
    }
    navigate('/dashboard');
    setIsLoading(false);
  };

  return {
    onSubmit,
    register,
    handleSubmit,
    errors,
    isLoading
  };
};
