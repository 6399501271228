import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useRideRequestProfile = () => {
  const { id } = useParams();
  const [rideRequestInfo, setRideRequestInfo] = useState({});

  const handleGetRideRequestInfo = async () => {
    const rideRequestDetail = JSON.parse(
      sessionStorage.getItem(`rideRequestDetail${id}`)
    );
    setRideRequestInfo(rideRequestDetail);
  };

  useEffect(() => {
    handleGetRideRequestInfo();
  }, []);

  return {
    rideRequestInfo
  };
};
