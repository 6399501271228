import React, { useEffect, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import ChatProvider from './ChatProvider';
import ChatContent from './content/ChatContent';
import ChatSidebar from './sidebar/ChatSidebar';
import {
  handleGetChatMessageList,
  handleGetChatThreadsList
} from 'api-service/chat';
import {
  store,
  setChatThread,
  setMessages,
  setScrollToBottom,
  setIsChatLoading
} from 'store';
import { useAppSelector } from 'store/hook';

const ChatTab = () => {
  const [hideSidebar, setHideSidebar] = useState(false);
  const { receiverId } = useAppSelector(state => state?.app);

  const handleSelect = () => {
    setHideSidebar(false);
    store.dispatch(setScrollToBottom(false));
  };

  useEffect(() => {
    const GetThreadList = async () => {
      const res = await handleGetChatThreadsList();
      const chatList = res?.data?.result?.users?.map(ele => ({
        id: ele.id,
        name: ele.first_name + ' ' + ele.last_name,
        profileImage: ele.profile_image
      }));

      store.dispatch(setChatThread({ chatThread: chatList }));
    };

    GetThreadList();
  }, []);

  useEffect(() => {
    const GetMessageList = async () => {
      const res = await handleGetChatMessageList(receiverId);
      if (res.status === 200) {
        store.dispatch(setMessages(res?.data?.result?.messages));
        store.dispatch(setIsChatLoading({ isChatLoading: false }));
      }
    };

    receiverId !== '' && GetMessageList();
  }, [receiverId]);

  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="0"
      onSelect={handleSelect}
    >
      <Card className="card-chat overflow-hidden">
        <Card.Body as={Flex} className="p-0 h-100">
          <ChatSidebar hideSidebar={hideSidebar} />
          <ChatContent setHideSidebar={setHideSidebar} />
        </Card.Body>
      </Card>
    </Tab.Container>
  );
};

const Chat = () => {
  return (
    <ChatProvider>
      <ChatTab />
    </ChatProvider>
  );
};

export default Chat;
