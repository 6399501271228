import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useRideRequestLocation = () => {
  const { id } = useParams();
  const [location, setLocation] = useState({});

  const handleGetRideRequestInfo = () => {
    const locationDetail = JSON.parse(
      sessionStorage.getItem(`rideBooking${id}`)
    );
    setLocation(locationDetail);
  };

  useEffect(() => {
    handleGetRideRequestInfo();
  }, []);

  return {
    location
  };
};
