import React from 'react';
import { Card, Col, Image, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDriverProfile } from './helper';

const ProfileIntro = () => {
  const { handleVehicleAction, userInfo } = useDriverProfile();
  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <Row>
            <div className={'d-flex justify-content-end mt-2 mb-2'}>
              <Button
                variant="primary"
                disabled={userInfo?.status === 'in_active'}
                onClick={() =>
                  handleVehicleAction({
                    id: userInfo?.id,
                    type: 'User',
                    status: 'in_active'
                  })
                }
              >
                In-Active
              </Button>
              <Button
                variant="primary"
                disabled={userInfo?.status === 'active'}
                onClick={() =>
                  handleVehicleAction({
                    id: userInfo?.id,
                    type: 'User',
                    status: 'active'
                  })
                }
                style={{ margin: '0 10px' }}
              >
                Active
              </Button>
              <Button
                variant="primary"
                disabled={userInfo?.status === 'pending'}
                onClick={() =>
                  handleVehicleAction({
                    id: userInfo?.id,
                    type: 'User',
                    status: 'pending'
                  })
                }
              >
                Pending
              </Button>
              <Button
                variant="primary"
                style={{ margin: '0 10px' }}
                disabled={userInfo?.status === 'block'}
                onClick={() =>
                  handleVehicleAction({
                    id: userInfo?.id,
                    type: 'User',
                    status: 'block'
                  })
                }
              >
                Block
              </Button>
            </div>
          </Row>
        </Card.Header>

        <Card.Body className="text-justify text-1000">
          <Row>
            <Col xs={12} md={12} className="d-flex justify-content-center">
              <Image
                src={`${process.env.REACT_APP_IMAGE_BASE_URL}${userInfo?.profile_image}`}
                width={'130px'}
                height={'130px'}
                className="m-auto"
                rounded
                alt={'profile img'}
              />
            </Col>
            <h1
              style={{
                margin: '10px auto',
                fontSize: '18px',
                textAlign: 'center'
              }}
            >{`Name: ${userInfo?.first_name} ${userInfo?.last_name}`}</h1>
            <h1
              style={{
                margin: '10px auto',
                fontSize: '18px',
                textAlign: 'center'
              }}
            >{`Phone No: +92 ${userInfo?.phone_number}`}</h1>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileIntro;

ProfileIntro.propTypes = {
  userInfo: PropTypes.object
};
