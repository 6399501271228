import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import { Nav } from 'react-bootstrap';

import { store, setReceiverId } from 'store';
import { setIsChatLoading } from 'store';

const ChatThread = ({ userInfo, index }) => {
  return (
    <Nav.Link
      eventKey={index}
      onClick={() => {
        store.dispatch(setReceiverId(userInfo.id));
        store.dispatch(setIsChatLoading({ isChatLoading: true }));
      }}
      className={classNames(`chat-contact hover-actions-trigger p-3`)}
    >
      <Flex>
        <Avatar
          src={`${process.env.REACT_APP_IMAGE_BASE_URL}${userInfo.profileImage}`}
          size="xl"
        />
        <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
          <Flex justifyContent="between">
            <h6 className="mb-0 chat-contact-title">{userInfo.name}</h6>
          </Flex>
        </div>
      </Flex>
    </Nav.Link>
  );
};

ChatThread.propTypes = {
  userInfo: PropTypes.object,
  index: PropTypes.number.isRequired
};

export default ChatThread;
