import React from 'react';
import { Link } from 'react-router-dom';

import { useNotification } from './helper';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ActionButton from 'components/common/ActionButton';
import DeleteConfirmModal from 'components/confirm-delete-modal';
import Loading from 'components/loading';

const NotificationList = () => {
  const { isLoading, notificationList } = useNotification();

  const data = `const columns = [
  {
    accessor: 'id',
    Header: 'No'
  },
  {
    accessor: 'title',
    Header: 'Title'
  },
  {
    accessor: 'description',
    Header: 'Description',
    Cell: ({ row }) => (
      <span>{row.original.description}</span>
    )
  }
];

  const data = ${JSON.stringify(notificationList, null, 2)}`;

  const searchableTableCode = `${data}

  function AdvanceTableExample() {

    return (
      <>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
      </>
    );
  }

  render (isLoading ? <Loading className={"loadingTable"} /> : <AdvanceTableExample />)`;
  return (
    <FalconComponentCard>
      <FalconComponentCard.Header
        title="Notifications"
        light={false}
        className="border-bottom border-200"
      />
      <FalconComponentCard.Body
        code={searchableTableCode}
        scope={{
          AdvanceTableSearchBox,
          AdvanceTableWrapper,
          AdvanceTableFooter,
          isLoading,
          Loading,
          AdvanceTable,
          ActionButton,
          DeleteConfirmModal,
          Link
        }}
        language="jsx"
        noInline
        noLight
      />
    </FalconComponentCard>
  );
};

export default NotificationList;
