import React from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';

import WeeklySales from './WeeklySales';

import { useDashboard } from './helper';

const Dashboard = () => {
  const { dashboardList, regionName, regionList } = useDashboard();
  return (
    <Row className="g-3 mb-3">
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {regionName}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {regionList?.map(el => (
            <Dropdown.Item
              key={el.id}
              href={`${location.pathname}?region=${el.name}`}
            >
              {el.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {Object.keys(dashboardList).map(function (key) {
        return (
          <Col md={3} xxl={3} key={key}>
            <WeeklySales
              data={{
                name: key
                  .split('_')
                  .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                  .join(' '),
                value: dashboardList[key]
              }}
            />
          </Col>
        );
      })}
      {/* <Col md={6} xxl={3}>
        <WeeklySales
          data={{ name: 'Today Earning', value: dashboardList?.today_earning }}
        />
      </Col> */}
      {/* <Col md={6} xxl={3}>
        <WeeklySales
          data={{
            name: 'Monthly Earning',
            value: dashboardList?.monthly_earning
          }}
        />
      </Col> */}
    </Row>
  );
};

export default Dashboard;
