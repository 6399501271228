import { handleGetRiderList } from 'api-service/rider';
import { HandleUpdateVehicleStatus } from 'api-service/driver';
import { useEffect, useState } from 'react';
import useRangeParams from 'hooks/useRangeParams';

export const useRiderList = () => {
  const [riderList, setRiderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { params } = useRangeParams();

  const getRiderList = async () => {
    setIsLoading(true);
    const res = await handleGetRiderList(params);
    if (res?.status === 200) {
      setRiderList(res?.data?.result?.riders);
      setIsLoading(false);
    }
  };

  const handleVehicleAction = async ({ id, type, status }) => {
    const data = {
      id,
      type,
      status
    };
    await HandleUpdateVehicleStatus(data);
    const newArr = [];
    riderList.forEach(rider => {
      if (rider.id == id) {
        rider.status = data.status;
        newArr.push(rider);
      } else {
        newArr.push(rider);
      }
    });
    setRiderList(newArr);
  };

  useEffect(() => {
    getRiderList();
  }, [params]);

  return {
    riderList,
    isLoading,
    handleVehicleAction
  };
};
