import { handleGetNotifications } from 'api-service/notification';
import { useEffect, useState } from 'react';
import useRangeParams from 'hooks/useRangeParams';

export const useNotification = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { params } = useRangeParams();

  const getNotificationList = async () => {
    setIsLoading(true);
    const res = await handleGetNotifications(params);
    if (res?.status === 200) {
      setNotificationList(res?.data?.result);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotificationList();
  }, [params]);

  return {
    notificationList,
    isLoading
  };
};
