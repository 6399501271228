import React from 'react';
import { Link } from 'react-router-dom';

import ActionButton from 'components/common/ActionButton';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

import { usePermissionList } from './helper';

const PermissionList = () => {
  const { permissionList } = usePermissionList();

  const data = `const columns = [
  {
    accessor: 'id',
    Header: 'No'
  },
  {
    accessor: 'first_name',
    Header: 'First Name'
  },
  {
    accessor: 'last_name',
    Header: 'Last Name'
  },
  {
    accessor: 'email',
    Header: 'Email'
  },
  {
    id: 'phone_number',
    Header: 'Phone No',
    Cell: ({ row }) =>\`\${row.original.dial_code} \${row.original.phone_number}\`
  },
  {
    Header: 'Action',
    Cell: ({ row }) => (
      <Link to={\`/driver-details/\${row.original.id}\`} className="p-0 me-2">
       <ActionButton icon="eye" title="View" variant="action" className="p-0 me-2" />
      </Link>
    )
  },
];

const data = ${JSON.stringify(permissionList, null, 2)}`;

  const searchableTableCode = `${data}

  function AdvanceTableExample() {

    return (
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={5}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={6}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    );
  }
  
  render(<AdvanceTableExample />)`;

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header
        title="Permission List"
        light={false}
        className="border-bottom border-200"
      />
      <FalconComponentCard.Body
        code={searchableTableCode}
        scope={{
          AdvanceTableSearchBox,
          AdvanceTableWrapper,
          AdvanceTableFooter,
          AdvanceTable,
          ActionButton,
          Link
        }}
        language="jsx"
        noInline
        noLight
      />
    </FalconComponentCard>
  );
};

export default PermissionList;
