import React from 'react';
import NavbarDropdown from './NavbarDropdown';
// import { dashboardRoutes } from 'routes/routes';
// import { Dropdown } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

const NavbarTopDropDownMenus = () => {
  // const navbarCollapsed = true;
  // const showBurgerMenu = true;

  // const handleDropdownItemClick = () => {
  //   // if (navbarCollapsed) {
  //   //   setConfig('navbarCollapsed', !navbarCollapsed);
  //   // }
  //   // if (showBurgerMenu) {
  //   //   setConfig('showBurgerMenu', !showBurgerMenu);
  //   // }
  // };
  return (
    <>
      <NavbarDropdown title="dashboard">
        {/* {dashboardRoutes.children[0].children.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))} */}
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;
