import {
  handleDeleteRecordApi,
  handleGetPropertyList
} from 'api-service/system-property-category';
import { useEffect, useState } from 'react';
import useRangeParams from 'hooks/useRangeParams';

export const usePropertyCategory = () => {
  const [systemPropertyCategoryList, setSystemPropertyCategoryList] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const [deleteId, setDeleteId] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { params } = useRangeParams();

  const getPropertyCategoryList = async () => {
    setIsLoading(true);
    const res = await handleGetPropertyList(params);
    if (res?.status === 200) {
      setSystemPropertyCategoryList(res?.data?.result);
      setIsLoading(false);
    }
  };

  const handleDeleteRecord = async () => {
    setDeleteLoading(true);
    const res = await handleDeleteRecordApi(deleteId);
    if (res?.status === 200) {
      getPropertyCategoryList();
      setDeleteLoading(false);
      setOpenDelete(false);
    }
  };

  useEffect(() => {
    getPropertyCategoryList();
  }, [params]);

  return {
    systemPropertyCategoryList,
    isLoading,
    openDelete,
    deleteLoading,
    setDeleteId,
    setOpenDelete,
    setDeleteLoading,
    handleDeleteRecord
  };
};
