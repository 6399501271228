import axios from 'utils/axios';
import { RIDE_REQUEST_LIST, UPDATE_RIDE_REQUEST } from 'utils/axios/endpoints';

export const handleGetRideRequestList = async (
  params = '',
  pagination = '?page=1&limit=10'
) => {
  const res = await axios.get(RIDE_REQUEST_LIST + pagination + params);
  return res;
};

export const handleUpdateRideRequestList = async (id, body) => {
  const res = await axios.post(UPDATE_RIDE_REQUEST + id, body);
  return res;
};
