import { handleUpdatePropertyServices } from 'api-service/system-property-service';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

export const useEditPropertyService = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formArr, setFormArr] = useState([
    {
      title: 'Name',
      name: 'name',
      value: '',
      type: 'text',
      required: true
    },
    {
      title: 'hotel service icon',
      name: 'system_property_service_icon',
      value: '',
      type: 'file',
      required: true
    }
  ]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const handleServiceData = () => {
    const serviceDetail = JSON.parse(
      sessionStorage.getItem(`serviceDetail${id}`)
    );
    setFormArr([
      {
        title: 'Name',
        name: 'name',
        type: 'text',
        value: serviceDetail.name
      },
      {
        title: 'hotel service icon',
        name: 'system_property_service_icon',
        type: 'file',
        value: serviceDetail.icon
      }
    ]);
  };

  useEffect(() => {
    handleServiceData();
  }, []);

  const onSubmit = async () => {
    setIsLoading(true);

    const formData = new FormData();

    // Convert data to FormData
    let bodyData = {};
    formArr.forEach(el => {
      if (el.type == 'file') {
        bodyData[el.name] = el.file;
      } else {
        bodyData[el.name] = el.value;
      }
    });

    Object.keys(bodyData).forEach(key => {
      formData.append(key, bodyData[key]);
    });

    // Delete a specific item from FormData
    formData.delete('system_property_service_icon');

    if (bodyData.system_property_service_icon) {
      for (let i = 0; i < bodyData.system_property_service_icon.length; i++) {
        formData.append(
          'system_property_service_icon',
          bodyData.system_property_service_icon[i]
        );
      }
    }

    const res = await handleUpdatePropertyServices(formData, id);
    if (res?.status === 200) {
      setIsLoading(false);
    }
    navigate('/system-service-category-list');
  };

  return {
    formArr,
    isLoading,
    control,
    errors,
    onSubmit,
    register,
    handleSubmit,
    setFormArr
  };
};
