import { useEffect, useState } from 'react';
import { handleGetMapList } from 'api-service/map-location';
import useRangeParams from 'hooks/useRangeParams';

export const useMapList = () => {
  const [mapList, setMapList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { params } = useRangeParams();

  const getMapList = async () => {
    setIsLoading(true);
    const res = await handleGetMapList(params);
    if (res?.status === 200) {
      setIsLoading(false);
      setMapList(res?.data?.result);
    }
  };

  useEffect(() => {
    getMapList();
  }, [params]);

  return {
    mapList,
    isLoading
  };
};
