import React from 'react';
import { Card, Col, Row, ListGroup } from 'react-bootstrap';
import Activity from 'components/common/Activity';

import { usePropertyRequestProfile } from './helper';
import ProfileIntro from './ProfileIntro';

const PropertyRequestProfile = () => {
  const { rideRequestInfo } = usePropertyRequestProfile();

  return (
    <>
      <Row className="g-3 mb-3">
        <Card className="mb-3">
          <Card.Header className="bg-light d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Property Request</h5>
            <h5 className="mb-0">#{rideRequestInfo.id}</h5>
          </Card.Header>
          <Card.Body className="text-justify text-1000">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <strong>Check In:</strong> {rideRequestInfo.check_in}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Check Out:</strong> {rideRequestInfo.check_out}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Room Type:</strong> {rideRequestInfo.room_type}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Status:</strong>
                {rideRequestInfo.status?.split('_').join(' ')}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Sub Total:</strong> {rideRequestInfo.sub_total}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Payment Status:</strong>{' '}
                {rideRequestInfo.payment_status?.split('_').join(' ')}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <Activity histories={rideRequestInfo?.propertyRequestHistory} />
        </Col>
        <Col lg={4}>
          {!(
            [null, undefined].includes(
              rideRequestInfo.property_owner_detail?.first_name
            ) || rideRequestInfo.property_owner_detail?.first_name == ''
          ) && (
            <ProfileIntro
              header="Property Owner Details"
              userInfo={rideRequestInfo.property_owner_detail}
            />
          )}
          {!(
            [null, undefined].includes(
              rideRequestInfo.user_detail?.first_name
            ) || rideRequestInfo.user_detail?.first_name == ''
          ) && (
            <ProfileIntro
              header="User Details"
              userInfo={rideRequestInfo.user_detail}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default PropertyRequestProfile;
