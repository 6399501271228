/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Flex from '../Flex';
import { useNavigate } from 'react-router-dom';

export const AdvancePagination = ({
  rowInfo,
  rowsPerPageSelection,
  navButtons,
  rowsPerPageOptions = [10, 20, 50],
  metaList,
  className
}) => {
  const navigate = useNavigate();

  const goToPage = page => {
    navigate(`${location.pathname}${page}`);
  };

  const fetchPerPage = (limit, page) => {
    navigate(`${location.pathname}?page=${page}&limit=${limit}`);
  };

  return (
    <Flex
      className={classNames(
        className,
        'align-items-center justify-content-between'
      )}
    >
      <Flex alignItems="center" className="fs--1">
        {rowInfo && (
          <p className="mb-0">
            <span className="d-none d-sm-inline-block me-2">
              {metaList.from} to {metaList.to}
              of {metaList.total}
            </span>
          </p>
        )}
        {rowsPerPageSelection && metaList.next_page_url && (
          <>
            <p className="mb-0 mx-2">Rows per page:</p>
            <Form.Select
              size="sm"
              className="w-auto"
              onChange={e =>
                fetchPerPage(Number(e.target.value), metaList.current_page)
              }
              defaultValue={metaList.per_page}
            >
              {rowsPerPageOptions.map(value => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </>
        )}
      </Flex>
      {navButtons && (
        <Flex>
          <Button
            size="sm"
            variant={metaList.prev_page_url ? 'primary' : 'light'}
            onClick={() => goToPage(metaList.prev_page_url)}
            className={classNames({ disabled: !metaList.prev_page_url })}
          >
            Previous
          </Button>
          <Button
            size="sm"
            variant={metaList.next_page_url ? 'primary' : 'light'}
            className={classNames('px-4 ms-2', {
              disabled: !metaList.next_page_url
            })}
            onClick={() => goToPage(metaList.next_page_url)}
          >
            Next
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default AdvancePagination;
