import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { handleUpdateService, handleGetRegionList } from 'api-service/services';

export const useEditService = () => {
  const navigate = useNavigate();
  const [regionList, setRegionList] = useState([]);
  const { id } = useParams();
  const [serviceType, setServiceType] = useState({
    title: 'Service Type',
    name: 'service_type',
    type: 'select',
    required: false,
    value: '',
    list: [
      { label: 'Car', value: 'car' },
      { label: 'Car Per Day', value: 'car_per_day' },
      { label: 'Bike', value: 'bike' },
      { label: 'Rickshaw', value: 'rickshaw' }
    ],
    perDayServicesList: [
      {
        id: 0,
        destroy: 0,
        name: '',
        capacity: '',
        per_day_charges: '',
        serviceCount: 0
      }
    ]
  });
  const [formArr, setFormArr] = useState([
    {
      title: 'Name',
      name: 'name',
      type: 'text',
      required: false
    },
    {
      title: 'Region',
      name: 'region_id',
      type: 'select',
      required: false,
      list: regionList
    },
    {
      title: 'Capacity',
      name: 'capacity',
      type: 'text',
      required: false
    },
    {
      title: 'Base Fare',
      name: 'base_fare',
      type: 'text',
      required: false
    },
    {
      title: 'Minimum Fare',
      name: 'minimum_fare',
      type: 'text',
      required: false
    },
    {
      title: 'Minimum Distance',
      name: 'minimum_distance',
      type: 'text',
      required: false
    },
    {
      title: 'Per Distance',
      name: 'per_distance',
      type: 'text',
      required: false
    },
    {
      title: 'Per minute Drive',
      name: 'per_minute_drive',
      type: 'text',
      required: false
    },
    {
      title: 'Time Limit',
      name: 'time_limit',
      type: 'text',
      required: false
    },
    {
      title: 'Per Minute Wait',
      name: 'per_minute_wait',
      type: 'text',
      required: false
    },
    {
      title: 'Payment Method',
      name: 'payment_method',
      type: 'select',
      list: [
        {
          value: 'cash',
          label: 'Cash'
        }
      ],
      required: false
    },
    {
      title: 'Commission Type',
      name: 'commission_type',
      type: 'select',
      list: [
        {
          value: 'percent',
          label: 'Percent'
        }
      ],
      required: false
    },
    {
      title: 'Admin Commission',
      name: 'admin_commission',
      type: 'text',
      required: false
    },
    {
      title: 'Image',
      name: 'service_image',
      type: 'file',
      required: false
    },
    {
      title: 'Description',
      name: 'description',
      type: 'text',
      required: false
    }
  ]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm();

  const handleServiceData = () => {
    const regionDetail = JSON.parse(
      sessionStorage.getItem(`serviceDetail${id}`)
    );
    setFormArr([
      {
        title: 'Name',
        name: 'name',
        value: regionDetail.name,
        type: 'text',
        required: false
      },
      {
        title: 'Region',
        name: 'region_id',
        value: regionDetail.region_id,
        type: 'select',
        required: false,
        list: regionList
      },
      {
        title: 'Capacity',
        name: 'capacity',
        value: regionDetail.capacity,
        type: 'text',
        required: false
      },
      {
        title: 'Base Fare',
        name: 'base_fare',
        value: regionDetail.base_fare,
        type: 'text',
        required: false
      },
      {
        title: 'Minimum Fare',
        name: 'minimum_fare',
        value: regionDetail.minimum_fare,
        type: 'text',
        required: false
      },
      {
        title: 'Minimum Distance',
        name: 'minimum_distance',
        value: regionDetail.minimum_distance,
        type: 'text',
        required: false
      },
      {
        title: 'Per Distance',
        name: 'per_distance',
        value: regionDetail.per_distance,
        type: 'text',
        required: false
      },
      {
        title: 'Per minute Drive',
        name: 'per_minute_drive',
        value: regionDetail.per_minute_drive,
        type: 'text',
        required: false
      },
      {
        title: 'Time Limit',
        name: 'time_limit',
        value: regionDetail.time_limit,
        type: 'text',
        required: false
      },
      {
        title: 'Per Minute Wait',
        name: 'per_minute_wait',
        value: regionDetail.per_minute_wait,
        type: 'text',
        required: false
      },
      {
        title: 'Payment Method',
        name: 'payment_method',
        value: regionDetail.payment_method,
        type: 'select',
        list: [
          {
            value: 'cash',
            label: 'Cash'
          }
        ],
        required: false
      },
      {
        title: 'Commission Type',
        name: 'commission_type',
        value: regionDetail.commission_type,
        type: 'select',
        list: [
          {
            value: 'percent',
            label: 'Percent'
          }
        ],
        required: false
      },
      {
        title: 'Admin Commission',
        name: 'admin_commission',
        value: regionDetail.admin_commission,
        type: 'text',
        required: false
      },
      {
        title: 'Image',
        name: 'service_image',
        value: regionDetail.image,
        type: 'file',
        required: regionDetail.image.length > 0 ? false : true
      },
      {
        title: 'Description',
        name: 'description',
        value: regionDetail.description,
        type: 'text',
        required: false
      },
      {
        title: 'Per Day Service List',
        name: 'perDayServicesList',
        value: regionDetail.perDayServices,
        type: 'none',
        required: false
      }
    ]);
    const newServiceType = {
      ...serviceType,
      value: regionDetail.service_type,
      perDayServicesList: regionDetail.perDayServices
    };
    setServiceType(newServiceType);
  };

  useEffect(() => {
    const getRegionList = async () => {
      const res = await handleGetRegionList();

      if (res.status === 200) {
        const list = res?.data.result.map(({ name, id }) => ({
          value: id,
          label: name
        }));

        setRegionList(list);
      }
    };

    getRegionList();
    handleServiceData();
  }, []);

  const onSubmit = async () => {
    const formData = new FormData();

    // Convert data to FormData
    let bodyData = {};
    formArr.forEach(el => {
      if (el.type == 'file') {
        bodyData[el.name] = el.file;
      } else {
        bodyData[el.name] = el.value;
      }
    });

    if (serviceType.value == 'car_per_day') {
      formData.append(`service_type`, serviceType.value);
      formData.append(
        `per_day_services_list`,
        JSON.stringify(serviceType.perDayServicesList)
      );
    }

    Object.keys(bodyData).forEach(key => {
      formData.append(key, bodyData[key]);
    });

    // Delete a specific item from FormData
    formData.delete('service_image');
    formData.delete('perDayServicesList');

    if (bodyData.service_image) {
      for (let i = 0; i < bodyData.service_image.length; i++) {
        formData.append('service_image', bodyData.service_image[i]);
      }
    }

    const res = await handleUpdateService(formData, id);
    res?.status === 200 && reset();

    navigate('/service-list');
  };

  return {
    regionList,
    formArr,
    control,
    errors,
    onSubmit,
    register,
    handleSubmit,
    setFormArr,
    serviceType,
    setServiceType
  };
};
