import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';

const MainLayout = ({ children }) => {
  const { hash, pathname } = useLocation();

  const isFluid = true;
  const navbarPosition = 'vertical'; //'combo'

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <div className={classNames('content', { 'pb-0': false })}>
        <NavbarTop />
        {/*------ Main Routes ------*/}
        {children}
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;

MainLayout.propTypes = { children: PropTypes.node };
